import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Button } from 'react-bootstrap'
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css';




const Login = () => {
	const [show, setShow] = useState(false);
	const [showscan, setShowscan] = useState(false);
	const handleClose = () => setShow(false);
	const videoshow = () => setShow(true);

	const handleClose1 = () => setShowscan(false);
	const cameracl = () => setShowscan(true);

    return (
        <>
	
<Modal className="all_supportss sm_sizess" id="registeress" show={show} onHide={handleClose}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
	<div className="fromss_boxx">
		<div className="titalss">
		 <h3>Register Now</h3>
		</div>
		<form action="">
		 <div className="unfildss" id="rediiobnt">
			<p>Register now for this event as :</p>
			<div className="rdo_boxx">
			<p>
				<input type="radio" id="test1" name="radio-group" checked />
				<label for="test1">Participant</label>
			</p>
			<p>
				<input type="radio" id="test2" name="radio-group" />
				<label for="test2">Volunteer</label>
			</p>

			</div>
		 </div>
		 <div class="form-group">
			 <label>Name<span className="red">*</span></label>
			 <input type="text" class="form-control" name="" placeholder="Enter Name" />
		 </div>

		 <div class="form-group">
			 <label>Email ID<span className="red">*</span></label>
			 <input type="text" class="form-control" name="" placeholder="Enter Email ID" />
		 </div>

		 <div class="form-group">
			 <label>Phone Number<span className="red">*</span></label>
			 <input type="text" class="form-control" name="" placeholder="Enter Phone Number" />
		 </div>

		 <div class="form-group check_txt" id="checkox">
		    <input id="check1" name="" type="checkbox" value="yes" />			
			<label for="check1">I confirm my phone number</label>			
		 </div>

		 <div class="form-group">
		  <div className="ck_titalss">	
		   <h3>Choose your best way for communication</h3>
		  </div>
		  <div className="row">
			<div className="col-lg-4 col-4">
			<div className="check_txtx" id="checkox">
		     <input id="check2" name="" type="checkbox" value="yes" />			
			 <label for="check2">Whatsapp</label>			
		    </div>
			</div>

			<div className="col-lg-4 col-4">
			<div className="check_txtx" id="checkox">
		     <input id="check3" name="" type="checkbox" value="yes" />			
			 <label for="check3">Phone Call</label>			
		    </div>
			</div>

			<div className="col-lg-4 col-4">
			<div className="check_txtx" id="checkox">
		     <input id="check4" name="" type="checkbox" value="yes" />			
			 <label for="check4">Email</label>			
		    </div>
			</div>
		  </div>
		 </div> 

		 <div class="form-group mt-4">
			<button type="submit" value="Register" class="sub_mitess">Register</button>
		 </div>
		</form>
	</div>
  </Modal.Body>
</Modal>

<Modal className="all_supportss sm_sizess" id="ScanUpload1" show={showscan} onHide={handleClose1}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
	<div className="fromss_boxx">
		<div className="titalss">
		 <h3>Scan or Upload QR Code</h3>
		</div>

		<div className="cm_box_area scan_fildss">
			<div className="cm_mgs"><img src="/images/camera.png" alt="" /></div>
			<div className="cm_bntss"><Link to="">Request Camera Permission</Link></div>
			<p><Link to="">Scan an image file</Link></p>
		</div>


		<div className="cm_box_area scan_fildss choo_im_img">
			<div className="cm_mgs"><img src="/images/camera.png" alt="" /></div>
			<div className="cm_bntss">
				<Link to="">Choose image - No image choosen</Link>
				<p>or drop an image to scan</p>
			</div>
			<p>scan using camera directly</p>
		</div>

		<div className="cm_box_area scan_fildss sc_blak_filess">
			<div className="cm_mgs"><img src="/images/scan_bg.png" alt="" /></div>
			<p><Link to="">Stop Scanning</Link></p>
		</div>
	
	</div>
  </Modal.Body>
</Modal>

	<div className="all_suport_bg">
	<section className="ser_v_araeea evnetsts profiles">
		<div className="container">
		<div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Impact</span></div>
		<div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events" className="act"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/referral"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><Button variant="primary" onClick={videoshow}><img src="/images/side_m_ic9.png" alt="" /> Logout</Button></Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>


						<div className="scanss">
						 <div className="scansses">							
							<Button variant="primary" onClick={cameracl}><h5>Scan Now <span className="scaan"><img src="/images/scanss.png" alt="" /></span></h5></Button>
						 </div> 
						 <p>Note : scan now for your <span> events/donations</span></p>
						</div>

                      </div>
                    </div>
		  <div className="col-lg-9">
		  <div className="entnts_tabss profff_eventss">
		  <h4>Events</h4>
		  <Tabs>
			<div className="tabs_list prof_eventss">

			{/* ---------- Mobile View ---------- */}
			<div className="mb_view_show mb-3">
			 <div className="fill_ters">
              <div className="inp_seach">
			   <img src="/images/search_g.png" alt="" />
			   <input type="text" class="inp_textx" name="" placeholder="Search" />
			  </div>
              <div className="filtters_sttt">
			   <img src="/images/ic_filter.png" alt="" />	
               <select className="filtters">
				<option value="">Filters</option>
				<option value="1">Lorem Ipsum</option>
				<option value="2">Lorem Ipsum</option>
				<option value="3">Lorem Ipsum</option>
				<option value="4">Lorem Ipsum</option>
			   </select>				
			  </div>
			  </div>
            </div>
            {/* ---------- Mobile View ---------- */}

			 <div className="tbdsa">
				<TabList>
					<Tab>Enrolled Events</Tab>
					<Tab>Upcoming Events</Tab>
				</TabList>
			 </div>		
			 <div className="fill_ters mb_view_hide">
              <div className="inp_seach">
			   <img src="/images/search_g.png" alt="" />
			   <input type="text" class="inp_textx" name="" placeholder="Search" />
			  </div>
              <div className="filtters_sttt">
			   <img src="/images/ic_filter.png" alt="" />	
               <select className="filtters">
				<option value="">Filters</option>
				<option value="1">Lorem Ipsum</option>
				<option value="2">Lorem Ipsum</option>
				<option value="3">Lorem Ipsum</option>
				<option value="4">Lorem Ipsum</option>
			   </select>				
			  </div>
			 </div>
			</div>

			<div className="content-wrapper">
				<TabPanel>
					<div className="main_tabs_data">
                      <div className="un_data_list">
					    <Link to="/events-details">
						<div className="usr_mgss"><img src="/images/events_sm.png" alt="" /></div>
						</Link>
						<div className="cntent_tx">
						 <div className="dts_shrerss">
						  <div className="dat_time">
							<span className="datess"><img src="/images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="/images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <div className="sh_linkss">
							<div className="ul_links">
								<ul>
									<li><Link to=""><img src="/images/g-calendar.png" alt="" /> &nbsp; Add to calender</Link></li>
								</ul>
							</div>
						  </div>
						  </div>	
						  <h2>Lorem ipsum dolor : sit amet  Sed conseq  <span className="peplss">200 people have registered</span></h2>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="/images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr">
							 <Link to="" className="red_mr" id="ck_in_time" style={{ display: 'none' }}>Check In : 05:00pm</Link>						 
							 <Link to="" className="red_mr fl_rights brd_5">Withdraw</Link>	
							 <Link to="" className="red_mr brd_5">Check In</Link>
							 <Link to="" className="red_mr brd_5" style={{ display: 'none' }}>Add Review</Link>							 						 
							</div>
						  </div>
						</div>
					  </div>


					  <div className="un_data_list">
					    <Link to="/events-details">
						<div className="usr_mgss"><img src="/images/who_abt.png" alt="" /></div>
						</Link>
						<div className="cntent_tx">
						 <div className="dts_shrerss">
						  <div className="dat_time">
							<span className="datess"><img src="/images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="/images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <div className="sh_linkss">
							<div className="ul_links">
								<ul>
									<li><Link to=""><img src="/images/g-calendar.png" alt="" /> &nbsp; Add to calender</Link></li>
								</ul>
							</div>
						  </div>
						  </div>	
						  <h2>Lorem ipsum dolor : sit amet  Sed conseq  <span className="peplss">200 people have registered</span></h2>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="/images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr">
							 <Link to="" className="red_mr" id="ck_in_time" style={{ display: 'none' }}>Check In : 05:00pm</Link>						 
							 <Link to="" className="red_mr fl_rights brd_5">Withdraw</Link>	
							 <Link to="" className="red_mr brd_5">Check In</Link>
							 <Link to="" className="red_mr brd_5" style={{ display: 'none' }}>Add Review</Link>					 
							</div>
						  </div>
						</div>
					  </div>


					  <div className="un_data_list">
					    <Link to="/events-details">
						<div className="usr_mgss"><img src="/images/events_sm4.png" alt="" /></div>
						</Link>
						<div className="cntent_tx">
						 <div className="dts_shrerss">
						  <div className="dat_time">
							<span className="datess"><img src="/images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="/images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <div className="sh_linkss">
							<div className="ul_links">
								<ul>
									<li><Link to=""><img src="/images/g-calendar.png" alt="" /> &nbsp; Add to calender</Link></li>
								</ul>
							</div>
						  </div>
						  </div>	
						  <h2>Lorem ipsum dolor : sit amet  Sed conseq  <span className="peplss">200 people have registered</span></h2>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="/images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr">
							 <Link to="" className="red_mr" id="ck_in_time" style={{ display: 'none' }}>Check In : 05:00pm</Link>						 
							 <Link to="" className="red_mr fl_rights brd_5">Withdraw</Link>	
							 <Link to="" className="red_mr brd_5">Check In</Link>	
							 <Link to="" className="red_mr brd_5" style={{ display: 'none' }}>Add Review</Link>					 						 
							</div>
						  </div>
						</div>
					  </div>


					<div className="pages_listst">
						<div className="row">
							<div className="col-lg-6">
								<div className="list_cnts">Showing 1 to 9 of 5 entries</div>
							</div>
							<div className="col-lg-6">
							<div className="paginationss">
											<ul>
											<li><Link to="">Previous</Link></li>	
											<li><Link to="" className="act">1</Link></li>
											<li><Link to="">2</Link></li>
											<li><Link to="">3</Link></li>	
											<li><Link to="">4</Link></li>
											<li><Link to="">Next </Link></li>	
											</ul>
										</div>			
							</div>
						</div>
					</div>

					  

					</div>
				</TabPanel>
				<TabPanel>
				<div className="main_tabs_data">
				<div className="un_data_list">
					    <Link to="/events-details">
						<div className="usr_mgss"><img src="/images/events_sm.png" alt="" />
						 <span className="uncommintss">Emergency Condition</span>
						</div>
						</Link>
						<div className="cntent_tx">
						 <div className="dts_shrerss">
						  <div className="dat_time">
							<span className="datess"><img src="/images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="/images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <div className="sh_linkss">
							<div className="ul_links">
								<ul>
									<li><Link to=""><img src="/images/g-calendar.png" alt="" /> &nbsp; Add to calender</Link></li>
									<li><Link to=""><img src="/images/copy_url.png" alt="" /></Link></li>
									<li><Link to=""><img src="/images/share-android_ic.png" alt="" /></Link></li>
								</ul>
							</div>
						  </div>
						  </div>	
						  <h2>Lorem ipsum dolor : sit amet  Sed conseq  <span className="peplss">200 people have registered</span></h2>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="/images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">
							<Button variant="primary" onClick={videoshow}>Register</Button></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="un_data_list">
					    <Link to="/events-details">
						<div className="usr_mgss"><img src="/images/who_abt.png" alt="" /></div>
						</Link>
						<div className="cntent_tx">
						 <div className="dts_shrerss">
						  <div className="dat_time">
							<span className="datess"><img src="/images/g_uil_calender.png" alt="" /> 20 May 2024</span>
							<span className="spacess">&nbsp;</span>
							<span className="datess"><img src="/images/tabler_clock.png" alt="" /> 4:00 - 6:00 pm</span>
						  </div>
						  <div className="sh_linkss">
							<div className="ul_links">
								<ul>
									<li><Link to=""><img src="/images/g-calendar.png" alt="" /> &nbsp; Add to calender</Link></li>
									<li><Link to=""><img src="/images/copy_url.png" alt="" /></Link></li>
									<li><Link to=""><img src="/images/share-android_ic.png" alt="" /></Link></li>
								</ul>
							</div>
						  </div>
						  </div>	
						  <h2>Lorem ipsum dolor : sit amet  Sed conseq  <span className="peplss">200 people have registered</span></h2>
						  <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum obcaecati voluptas est 
							aliquam soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus 
							minima eum obcaecati voluptas est aliquam soluta. Aut quia minusLorem Lorem ipsum dolor sit amet. Sed 
							consequatur necessitatibus aut natus minima eum obcaecati voluptas e</p>
						  <div className="adress">
							<div className="adrss"><img src="/images/g_ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</div>
                            <div className="red_mr"><Link to="" className="red_mr">
							<Button variant="primary" onClick={videoshow}>Register</Button></Link></div>
						  </div>
						</div>
					  </div>


					  <div className="pages_listst">
						<div className="row">
							<div className="col-lg-6 col-12">
								<div className="list_cnts">Showing 1 to 9 of 5 entries</div>
							</div>
							<div className="col-lg-6 col-12">
							<div className="paginationss">
											<ul>
											<li><Link to="">Previous</Link></li>	
											<li><Link to="" className="act">1</Link></li>
											<li><Link to="">2</Link></li>
											<li><Link to="">3</Link></li>	
											<li><Link to="">4</Link></li>
											<li><Link to="">Next </Link></li>	
											</ul>
										</div>			
							</div>
						</div>
					</div>

					</div>
				</TabPanel>
			</div>
		  </Tabs>
		  </div>
		  </div>
		  </div>

		
		</div>
	</section>
</div>


	</>
    )
};

export default Login;