import { Link } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const FAQs = () => {
  const [generalFAQData, setGeneralFAQData] = useState([]);
  const [volunteerFAQData, setVolunteerFAQData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGenalFAQData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(`/web/getGeneralFaq?language=en`);
      if (!data.data.error) {
        console.log(data);
        setGeneralFAQData(data.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getvolunterData = async () => {
    try {
      const data = await axiosClient.get(`web/getVolunteerFaq?language=en`);
      if (!data.data.error) {
        setVolunteerFAQData(data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGenalFAQData();
    getvolunterData();
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <section className="ser_v_araeea green_bg manages_heis" id="about_pgs">
        <img src="/images/flo_partss.png" className="flo_partss" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="al_sprts_pages">
                <h3>FAQ’s</h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cnt_datas">
                Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut
                natus minima eum obcaecati voluptas est aliquam soluta. Aut quia
                minus Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                aut natus minima eum
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ser_v_araeea" id="faqss">
        <div className="container">
          {generalFAQData.length !== 0 && (
            <div className="row">
              <div className="col-lg-3">
                <div className="faq_heddigs">General FAQ’s</div>
              </div>
              <div className="col-lg-9">
                <div className="accordion_container">
                  <Accordion>
                    {generalFAQData.map((res) => (
                      <AccordionItem header={res.question}>
                        <div
                          dangerouslySetInnerHTML={{ __html: res.answer }}
                        ></div>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          {volunteerFAQData.length !== 0 && (
            <div className="row">
              <div className="col-lg-3">
                <div className="faq_heddigs">volunteers FAQ’s</div>
              </div>
              <div className="col-lg-9">
                <div className="accordion_container">
                  <Accordion>
                    {volunteerFAQData.map((res) => (
                      <AccordionItem header={res.question}>
                        <div
                          dangerouslySetInnerHTML={{ __html: res.answer }}
                        ></div>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="ser_v_araeea pt-0">
        <div className="container">
          <div className="faq_bgrr">
            <img src="/images/faq_bgrr.png" className="bg_faqq" alt="" />
            <div className="over_cntss">
              <div className="medialss">
                <h2 className="text-light">Still Need Help?</h2>
                <p>We’re here to support</p>
                <span>
                  Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut
                  natus minima eum obcaecati voluptas est aliquam soluta. Aut
                  quia minus Lorem ipsum dolor sit amet. Sed consequatur
                  necessitatibus aut natus minima eum{" "}
                </span>
                <div className="link_cntnts">
                  <Link to="/contact">
                    Contact Us{" "}
                    <img src="/images/ar_right_icon.png" alt=""></img>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQs;
