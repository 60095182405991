import { Link, useNavigate, useParams } from "react-router-dom";
import { React, useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axiosClient from "../Services/Axios/axios";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";

const Donate = () => {
  const { id } = useParams();
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const [stripeData, setStripeData] = useState({
    campaign_id: id,
    amount: 0,
    currency: "USD",
    paymentMethodType: "qr",
    tips: 0,
    reciept: null,
    email: "",
    name: "",
    hide_name: "1",
    user_id: "",
    description: "Payment for campaign 3 - Tip included", // Add a description here
  });
  const [clientSecret, setClientSecret] = useState("");
  const [donationData, setDonationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ file: "" });

  // Function to handle payment

  const getDonationData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(
        `/campaigns/getCampaignDetailsById/${id}?language=en`
      );
      if (!data.data.error) {
        setDonationData(data.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handlePayment = async (event) => {
    event.preventDefault(); // Prevent form submission

    const Headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZWZhdWx0VXNlciI6MzA5OCwidXNlclR5cGUiOjUsImlhdCI6MTczMTUxMjUwNywiZXhwIjoxNzMxNTk4OTA3fQ.GyNcakgR5XPB9zS-Qs7clnk7OvlhS4d3b3PxtDretP4`,
      },
    };

    try {
      // const response = await axiosClient.post(
      //   "/campaigns/create-payment-intent",
      //   stripeData,
      //   Headers
      // );
      // Request to backend to create payment intent
      if (stripeData.paymentMethodType === "card") {
        const response = await axiosClient.post(
          "/campaigns/create-payment-intent",
          { ...stripeData, reciept: "" },
          Headers
        );

        const { client_secret } = response.data;
        setClientSecret(client_secret);

        const cardElement = elements.getElement(CardNumberElement);

        const { error, paymentIntent } = await stripe.confirmCardPayment(
          client_secret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: stripeData.name,
                email: stripeData.email,
                // address: {
                //   // line1: "123 Main Street",
                //   city: "Mumbai",
                //   // state: "Maharashtra",
                //   postal_code: "400001",
                //   country: "IN",
                // },
              },
            },
          }
        );

        if (error) {
          Swal.fire({
            icon: "error",
            title: "Payment failed",
            text: error.message,
          });
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
          const response = await axiosClient.post("/campaigns/temp_webhook", {
            campaign_id: id,
            client_secret: client_secret,
          });
          if (!response.data.error) {
            Swal.fire({
              icon: "success",
              title: "Payment successful",
            });
            navigate(`/donation-successful/${id}`, {
              state: response.data.Data,
            });
          }
        }
      } else {
        const formData = new FormData();
        formData.append("image", stripeData.reciept);
        const pdfResponse = await axiosClient.post(
          "/users/uploadimg",
          formData
        );
        if (!pdfResponse.data.error) {
          const response = await axiosClient.post("/campaigns/direct-payment", {
            ...stripeData,
            reciept: pdfResponse.data.file,
          });
          if (!response.data.error) {
            Swal.fire({
              icon: "success",
              title: "Payment successful",
            });
            navigate(`/donation-successful/${id}`, {
              state: response.data.transactionId,
            });
          }
        }
      }
    } catch (err) {
      console.log("Error creating payment intent:", err);
      alert("Failed to create payment intent");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validFileTypes = ["application/pdf", "image/jpeg", "image/png"];

    if (file && !validFileTypes.includes(file.type)) {
      setError({
        ...error,
        file: "Invalid file type. Please select a PDF, JPG, or PNG file.",
      });
      event.target.value = null;
      return;
    }

    setError({
      ...error,
      file: "",
    });
    setStripeData({
      ...stripeData,
      reciept: file,
    });
  };

  useEffect(() => {
    getDonationData();
  }, []);
  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <div className="all_suport_bg">
        <section className="ser_v_araeea" id="pay_alss">
          <div className="container">
            <div className="cntr_box_all">
              {donationData.id && (
                <div className="top_user justify-content-start">
                  <div className="usr_pickss">
                    <img src={donationData.donation_images} alt="" />
                  </div>
                  <div className="cnt_tx_cntss">
                    <span>You’re supporting</span> {donationData.donation_title}
                  </div>
                </div>
              )}

              <div className="form_arar">
                <form action="">
                  <div className="price_list">
                    <h6>Enter your Donation</h6>
                    <ul>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "50" })
                        }
                        className={stripeData.amount === "50" ? "active" : ""}
                      >
                        $50
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "100" })
                        }
                        className={stripeData.amount === "100" ? "active" : ""}
                      >
                        $100
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "200" })
                        }
                        className={stripeData.amount === "200" ? "active" : ""}
                      >
                        $200
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "300" })
                        }
                        className={stripeData.amount === "300" ? "active" : ""}
                      >
                        $300
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "500" })
                        }
                        className={stripeData.amount === "500" ? "active" : ""}
                      >
                        $500
                      </li>
                      <li
                        onClick={() =>
                          setStripeData({ ...stripeData, amount: "1000" })
                        }
                        className={stripeData.amount === "1000" ? "active" : ""}
                      >
                        $1,000
                      </li>
                    </ul>
                  </div>

                  <div class="form-group marg_cds">
                    <span className="setss">.00</span>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      placeholder="USD"
                      value={stripeData.amount}
                      onChange={({ target }) =>
                        setStripeData({ ...stripeData, amount: target.value })
                      }
                      required
                    />
                  </div>

                  <div class="form-group manags_cntxt">
                    Donation Amount{" "}
                    <span className="pricde">USD {stripeData.amount}</span>
                  </div>

                  <div className="brd_liness mb-4">&nbsp;</div>

                  <div class="form-group marg_cds">
                    <span className="setss">{stripeData.tips}</span>
                    <input
                      type="text"
                      class="form-control"
                      name=""
                      placeholder="Tip"
                      value={stripeData.tips}
                      onChange={({ target }) =>
                        setStripeData({ ...stripeData, tips: target.value })
                      }
                    />
                    <span className="sm_tittlss">
                      Worldtrust relies on your generosity to continue providing
                      0% platform fee for fundraisers.
                    </span>
                  </div>

                  <div class="form-group manags_cntxt clr_blakk mt-3 mb-4">
                    Payment
                  </div>

                  <div class="form-group manags_cntxt">
                    Total Donation Amount{" "}
                    <span className="pricde">
                      USD {+stripeData.tips + +stripeData.amount}
                    </span>
                  </div>

                  <div className="price_list link_btnss mt-3" id="pay_crd_bnks">
                    <h6>Select Payment Option</h6>
                    <Tabs>
                      <div className="tabs_list">
                        <TabList>
                          <Tab
                            onClick={() =>
                              setStripeData({
                                ...stripeData,
                                paymentMethodType: "qr",
                              })
                            }
                          >
                            QR
                          </Tab>
                          <Tab
                            onClick={() =>
                              setStripeData({
                                ...stripeData,
                                paymentMethodType: "bank",
                              })
                            }
                          >
                            Bank
                          </Tab>
                          <Tab
                            onClick={() =>
                              setStripeData({
                                ...stripeData,
                                paymentMethodType: "card",
                              })
                            }
                          >
                            Card
                          </Tab>
                        </TabList>
                      </div>
                      <div className="content-wrapper">
                        <TabPanel>
                          <div className="mang_und_listst">
                            <form>
                              <div className="qr_coddd">
                                <p className="titalss">
                                  Scan with your bank app or payment app
                                </p>
                                <div className="qr_boxxss">
                                  <img src="/images/qr_codes.png" alt="" />
                                </div>
                                <span>
                                  Payments collected via Stripe. Payee name may
                                  appear as "Stripe Payments Pte. Ltd.".
                                </span>
                              </div>

                              <div class="brd_liness mt-2 mb-3">&nbsp;</div>
                              <div class="form-group" id="browsers">
                                <label>Attach Reciept</label>
                                <input
                                  type="file"
                                  class="form-control"
                                  name=""
                                  placeholder="Attach Reciept"
                                  accept=".pdf, .jpg, .jpeg, .png"
                                  onChange={handleFileChange}
                                  required
                                />
                                {error.file !== "" && (
                                  <div className="text-danger mt-1">
                                    {error.file}
                                  </div>
                                )}
                              </div>
                              <div class="brd_liness mt-4 mb-4">&nbsp;</div>

                              <div class="form-group">
                                <label>Your Full Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your name"
                                  value={stripeData.name}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      name: target.value,
                                    })
                                  }
                                  required
                                />
                              </div>

                              <div class="form-group">
                                <label>Your Email</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your email"
                                  value={stripeData.email}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      email: target.value,
                                    })
                                  }
                                  required
                                />
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  You will receive an email receipt for your
                                  donation.{" "}
                                </p>
                              </div>

                              <div class="form-group check_txt" id="checkox">
                                <input
                                  id="check1"
                                  name=""
                                  type="checkbox"
                                  value="yes"
                                />
                                <label for="check1">
                                  Hide name from everyone but fundraiser
                                </label>
                              </div>

                              <div class="form-group mt-5 mb-4">
                                <button
                                  type="button"
                                  value="Donate Now"
                                  className="sub_mitess"
                                  onClick={handlePayment}
                                >
                                  Donate Now
                                </button>
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  By choosing the payment method above, you
                                  agree to the Worldtrust{" "}
                                  <Link to="">Terms of Service</Link> and
                                  acknowledge the{" "}
                                  <Link to="">Privacy Notice.</Link>
                                </p>
                              </div>
                              <div class="brd_liness mt-4">&nbsp;</div>

                              <div className="gpr_g_textss">
                                <img src="/images/trapy_gr.png" alt="" />
                                <div className="grp_txtx">
                                  <h6>Worldtrust protects your donation</h6>
                                  <p>
                                    We guarantee you a full refund for up to a
                                    year in the rare case that fraud occurs. See
                                    our Worldtrust Giving Guarantee.
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="mang_und_listst">
                            <form>
                              <h6>Bank Details</h6>

                              <div class="pri_liststs mt-4">
                                <div class="form-group manags_cntxt">
                                  Bank Name{" "}
                                  <span class="pricde black_tx">City Bank</span>
                                </div>
                                <div class="form-group manags_cntxt">
                                  Account No.{" "}
                                  <span class="pricde black_tx">
                                    8675876847548432
                                  </span>
                                </div>
                                <div class="form-group manags_cntxt">
                                  IFSC Code{" "}
                                  <span class="pricde black_tx">IUT89HKJ5</span>
                                </div>
                                <div class="form-group manags_cntxt">
                                  Amount{" "}
                                  <span class="pricde black_tx">
                                    200.00 USD
                                  </span>
                                </div>
                              </div>

                              <div class="brd_liness mt-2 mb-3">&nbsp;</div>
                              <div class="form-group" id="browsers">
                                <label>Attach Reciept</label>
                                <input
                                  type="file"
                                  class="form-control"
                                  name=""
                                  placeholder="Attach Reciept"
                                  accept=".pdf, .jpg, .jpeg, .png"
                                  onChange={handleFileChange}
                                  required
                                />
                                {error.file !== "" && (
                                  <div className="text-danger mt-1">
                                    {error.file}
                                  </div>
                                )}
                              </div>
                              <div class="brd_liness mt-4 mb-4">&nbsp;</div>

                              <div class="form-group">
                                <label>Your Full Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your name"
                                  value={stripeData.name}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      name: target.value,
                                    })
                                  }
                                  required
                                />
                              </div>

                              <div class="form-group">
                                <label>Your Email</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your email"
                                  value={stripeData.email}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      email: target.value,
                                    })
                                  }
                                  required
                                />
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  You will receive an email receipt for your
                                  donation.{" "}
                                </p>
                              </div>

                              <div class="form-group check_txt" id="checkox">
                                <input
                                  id="check1"
                                  name=""
                                  type="checkbox"
                                  value="yes"
                                />
                                <label for="check1">
                                  Hide name from everyone but fundraiser
                                </label>
                              </div>

                              <div class="form-group mt-5 mb-4">
                                <button
                                  type="button"
                                  value="Donate Now"
                                  className="sub_mitess"
                                  onClick={handlePayment}
                                >
                                  Donate Now
                                </button>
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  By choosing the payment method above, you
                                  agree to the Worldtrust{" "}
                                  <Link to="">Terms of Service</Link> and
                                  acknowledge the{" "}
                                  <Link to="">Privacy Notice.</Link>
                                </p>
                              </div>
                              <div class="brd_liness mt-4">&nbsp;</div>

                              <div className="gpr_g_textss">
                                <img src="/images/trapy_gr.png" alt="" />
                                <div className="grp_txtx">
                                  <h6>Worldtrust protects your donation</h6>
                                  <p>
                                    We guarantee you a full refund for up to a
                                    year in the rare case that fraud occurs. See
                                    our Worldtrust Giving Guarantee.
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="mang_und_listst">
                            <form>
                              <h6>Card Details</h6>
                              <div class="form-group">
                                <label>Card Type</label>
                                <select className="slt_st fulls_d">
                                  <option value="">Choose card type</option>
                                  <option value="">Credit</option>
                                  <option value="">Debit</option>
                                </select>
                              </div>

                              <div class="form-group">
                                <label>Card No.</label>
                                <CardNumberElement className="paymentInput" />
                              </div>

                              <div class="form-group">
                                <div className="row">
                                  <div className="col-lg-5">
                                    <label>Expiry Date</label>
                                    {/* <div className="bothss_slts">
                                      <select className="slt_st">
                                        <option value="">Feb</option>
                                      </select>

                                      <select className="slt_st">
                                        <option value="">2022</option>
                                      </select>
                                    </div> */}

                                    <CardExpiryElement className="paymentInput" />
                                  </div>

                                  <div className="col-lg-3">
                                    <label>CVV</label>
                                    {/* <input
                                      type="text"
                                      class="form-control"
                                      name=""
                                      placeholder="xxxx"
                                      required=""
                                    /> */}

                                    <CardCvcElement className="paymentInput " />
                                  </div>

                                  <div className="col-lg-12">
                                    <div
                                      class="form-group check_txt mt-3"
                                      id="checkox"
                                    >
                                      <input
                                        id="check3"
                                        name=""
                                        type="checkbox"
                                        value="yes"
                                      />
                                      <label for="check3">
                                        Save card for later use
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <CardElement /> */}

                              <div class="brd_liness mt-2 mb-3">&nbsp;</div>

                              <div class="form-group">
                                <label>Your Full Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your name"
                                  value={stripeData.name}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      name: target.value,
                                    })
                                  }
                                  required
                                />
                              </div>

                              <div class="form-group">
                                <label>Your Email</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name=""
                                  placeholder="Enter your email"
                                  value={stripeData.email}
                                  onChange={({ target }) =>
                                    setStripeData({
                                      ...stripeData,
                                      email: target.value,
                                    })
                                  }
                                  required
                                />
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  You will receive an email receipt for your
                                  donation.{" "}
                                </p>
                              </div>

                              <div class="form-group check_txt" id="checkox">
                                <input
                                  id="check1"
                                  name=""
                                  type="checkbox"
                                  value="yes"
                                />
                                <label for="check1">
                                  Hide name from everyone but fundraiser
                                </label>
                              </div>

                              <div class="form-group mt-5 mb-4">
                                <button
                                  type="button"
                                  value="Donate Now"
                                  className="sub_mitess"
                                  onClick={handlePayment}
                                >
                                  Donate Now
                                </button>
                              </div>

                              <div className="form-group">
                                <p className="by_textxt">
                                  By choosing the payment method above, you
                                  agree to the Worldtrust{" "}
                                  <Link to="">Terms of Service</Link> and
                                  acknowledge the{" "}
                                  <Link to="">Privacy Notice.</Link>
                                </p>
                              </div>
                              <div class="brd_liness mt-4">&nbsp;</div>

                              <div className="gpr_g_textss">
                                <img src="/images/trapy_gr.png" alt="" />
                                <div className="grp_txtx">
                                  <h6>Worldtrust protects your donation</h6>
                                  <p>
                                    We guarantee you a full refund for up to a
                                    year in the rare case that fraud occurs. See
                                    our Worldtrust Giving Guarantee.
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Donate;
