import React from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import axiosClient from "../../Services/Axios/axios";
const AuthScreen = () => {
  const navigate = useNavigate();

  // const firebaseConfig = {
  //   apiKey: "AIzaSyCz782oRF2y40mF9OibqcQb2rrLvpPKsOo",
  //   authDomain: "ngo-worldtrust.firebaseapp.com",
  //   projectId: "ngo-worldtrust",
  //   storageBucket: "ngo-worldtrust.firebasestorage.app",
  //   messagingSenderId: "868872688972",
  //   appId: "1:868872688972:web:87d757c7c33887d33d2be9",
  //   measurementId: "G-RK9MEG6SHR",
  // };


  const firebaseConfig = {
    apiKey: "AIzaSyCP7VYo-EyYE_tYwP3KmFPYpC_yM-URSYE",
    authDomain: "worldtrust-e9f31.firebaseapp.com",
    projectId: "worldtrust-e9f31",
    storageBucket: "worldtrust-e9f31.firebasestorage.app",
    messagingSenderId: "182318616657",
    appId: "1:182318616657:web:84fdd2fd52ab24b91fa870",
    measurementId: "G-52SGHJB092"
  };

  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "2",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(response.data.message || "Failed to login with Google");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Google Login Failed",
        text:
          error.message ||
          "An error occurred during Google login. Please try again.",
      });
    }
  };

  const handleFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const userData = {
        email: user.email,
        first_name: user.displayName.split(" ")[0],
        last_name: user.displayName.split(" ").slice(1).join(" "),
        profile_image: user.photoURL,
        signup_type: "1",
      };

      const response = await axiosClient.post(
        "/users/social-media-register",
        userData
      );
      if (response.data && !response.data.error) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "Welcome to WorldTrust!",
        }).then(() => {
          navigate("/");
        });
      } else {
        throw new Error(
          response.data.message || "Failed to login with Facebook"
        );
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Facebook Login Failed",
        text:
          error.message ||
          "An error occurred during Facebook login. Please try again.",
      });
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex justify-content-center login-container">
      <div className="row w-100 h-100">
        {/* Left side image */}
        <div className="col-md-6 d-none d-md-block">
          {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
        </div>

        {/* Right side login form */}
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center h-100">
          <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex justify-content-center   ">
              <img src={LogoIcon} alt="WorldTrust" />
            </div>
            <h3 className="text-center">Welcome to WorldTrust</h3>
            <p className="text-center fw-bolder ">
              Register using Email Address
            </p>
            <div className="d-flex justify-content-center   ">
              <button
                className="btn bg-natural"
                onClick={() => navigate("/register")}
              >
                Register with Email
              </button>
            </div>

            {/* <form>
              <div className="form-group mb-3">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group mb-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                />
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-block">
                  Register With Email
                </button>
              </div>
            </form> */}

            <p className="text-center mt-3">
              If you're already registered, <Link to="/login">Login</Link>
            </p>

            <div className="text-center">Or continue with</div>

            <div className="d-flex flex-column align-items-center gap-2 mt-3  ">
              <button className="btn btn-danger" onClick={handleGoogleLogin}>
                {" "}
                Use My Google Account
              </button>
              <button
                className="btn btn-facebook mt-2 "
                onClick={handleFacebookLogin}
              >
                Use My Facebook Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;
