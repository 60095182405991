import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Button } from 'react-bootstrap'
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css';


const Careers = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);

    return (
        <>



<div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
            <div className="container">
                <div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Profile</span></div>
                <div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/referral"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings" className="act"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><img src="/images/side_m_ic9.png" alt="" /> Logout</Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-9">
                    <div className="form_boxx profilss">
                    <h4 class="br_space">Change Password</h4>
                  <div className="form_arar mt-0"> 
                  <div className="mang_und_listst mt-0">  
                    <form action="">
                       <div className="row">                           
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Existing Password</label>
                                    <input type="password" class="form-control" name="" placeholder="Enter your existing password" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>New Password (At least 8 Characters)</label>
                                    <input type="password" class="form-control" name="" placeholder="Enter your new password" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Confirm Password</label>
                                    <input type="password" class="form-control" name="" placeholder="Confirm password" />
                                </div>
                            </div>
                       </div>

                        <div className="row">
                        <div className="col-lg-6">
                         <div class="form-group">
                         <label>Security Pin</label>
                         <p className="viewss">View security Password : 19** <Link to=""><img src="/images/sm_eye.png" alt="" /></Link></p>
                         </div>
                        </div>
                             

                          <div className="col-lg-12 mt-5">
                              <div class="form-group">
                                <button type="submit" value="Update Profile" class="sub_mitess">Save</button>
                              </div>
                            </div>
                        </div>

                        
                    </form>
                  </div> 
                  </div> 
                </div>
                    </div>
                </div>
                
            </div>
        </section>
        </div>
    </>
    )
};

export default Careers;