import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from 'react-image-upload'

const Careers = () => {
    const [startDate, setStartDate] = useState(new Date());

    function getImageFileObject(imageFile) {
        console.log({ imageFile })
      }
    
      function runAfterImageDelete(file) {
        console.log({ file })
      }

    return (
        <>
<div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
            <div className="container">
                <div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Profile</span></div>
                <div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile" className="act"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/wallet"><img src="/images/side_m_ic6.png" alt="" /> Wallet</Link></li>
                           <li><Link to="/referral"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><img src="/images/side_m_ic9.png" alt="" /> Logout</Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-lg-9">
                    <div className="form_boxx profilss">
                  
                  <div className="form_arar mt-0"> 
                  <h4>Profile</h4> 
                  <div className="mang_und_listst mt-0">  
                    <form action="">
                        <div className="row">
                          <div className="col-lg-9">
                          <div className="row">                            
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter first name" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter last name" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter email address" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Mobile No.</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter phone number" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Gender</label>
                                    <select className="form-control">
                                        <option value="">Choose your gender</option>
                                    </select>
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <div class="form-group relatives">
                                    <label>Date of Birth</label>   
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  />
                                    <img src="/images/calenders.png" alt="" />                                 
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Referral Code</label>                                    
                                    <input type="text" class="form-control" name="" placeholder="Enter referral code" />
                                </div>
                            </div>

                            

                        </div>                            
                          </div>
                          <div className="col-lg-3">
                             <div id="imgss_upload">
                             <ImageUploader
                                    onFileAdded={(img) => getImageFileObject(img)}
                                    onFileRemoved={(img) => runAfterImageDelete(img)}
                                    />
                             </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                              <div class="form-group">
                                <button type="submit" value="Update Profile" class="sub_mitess">Update Profile</button>
                              </div>
                            </div>
                        </div>

                        
                    </form>
                  </div> 
                  </div> 
                </div>
                    </div>
                </div>
                
            </div>
        </section>
        </div>
    </>
    )
};

export default Careers;