import { json, Link, useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { useState, useEffect } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";

const Header = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [borderShow, setBorderShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});

  const userData = localStorage.getItem("userData");

  console.log(userDetail);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 82
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setBorderShow(false);
    } else {
      setBorderShow(true);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to logout?",
      showCancelButton: true,
      confirmButtonText: "Log out",
    }).then((result) => {
      if (result.isConfirmed) {
        navigation("/");
        localStorage.clear();
        Swal.fire("Log out", "", "success");
      }
    });
  };

  useEffect(() => {
    if (userData) {
      const data = JSON.parse(userData);
      setUserDetail(data);
    }
  }, [userData]);

  return (
    <>
      <header
        className={`header-section ${borderShow === true ? "bor_botmm" : ""}`}
        id="fixedds"
      >
        <div className="container">
          <nav>
            <div className="line justify-content-between">
              <div className="lgo_mg">
                <Link to="/">
                  <img src="/images/w_logo.png" alt="" className="lgo_parts" />
                </Link>
              </div>

              <div className={`top-nav s-12 l-12 ${isMenuOpen ? "open" : ""}`}>
                <ul>
                  <li>
                    <Link to="/" onClick={toggleMenu}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={toggleMenu}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/donate" onClick={toggleMenu}>
                      Donate{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/events" onClick={toggleMenu}>
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={toggleMenu}>
                      Contact Us
                    </Link>
                  </li>
                  {userData === null && (
                    <>
                      <li className="mb_view_show">
                        <Link to="/auth-screen" onClick={toggleMenu}>
                          Login
                        </Link>
                      </li>
                      <li className="mb_view_show">
                        <Link to="/auth-screen" onClick={toggleMenu}>
                          Sign up
                        </Link>
                      </li>
                    </>
                  )}
                </ul>

                <div className="bk_now_flds mb_view_hide">
                  {/* <div id="loging_userss">
                    <div className="notif_tion">
                      <span className="countss">2</span>
                      <img
                        src="//images/notification.png"
                        alt=""
                        className="lgo_parts"
                      />
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="usr_sm_mgss">
                          <img
                            src="//images/userr_sm_img.png"
                            alt=""
                            className="lgo_parts"
                          />
                        </div>
                        Jackson
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="">Profile</Dropdown.Item>
                        <Dropdown.Item href="">Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}

                  {userData === null ? (
                    <ul>
                      <li>
                        <Link to="/auth-screen">Login</Link>{" "}
                        <span className="spss_lst">/</span>{" "}
                        <Link to="/auth-screen">Sign up</Link>
                      </li>
                    </ul>
                  ) : (
                    userDetail.user && (
                      <div id="loging_userss">
                        <div className="notif_tion">
                          <span className="countss">2</span>
                          <img
                            src="/images/notification.png"
                            alt=""
                            className="lgo_parts"
                          />
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="usr_sm_mgss">
                              <img
                                src="/images/userr_sm_img.png"
                                alt=""
                                className="lgo_parts"
                              />
                            </div>
                            {userDetail.user.first_name.split(" ")[0]}
                            {/* smit */}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="">Profile</Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout} href="">
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )
                  )}
                </div>
              </div>
              <button className="hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
