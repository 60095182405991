import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Button } from 'react-bootstrap'
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css';




const Login = () => {
	const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const videoshow = () => setShow(true);

    return (
        <>

		
<Modal className="all_supportss sm_sizess" id="LogoutPage" show={show} onHide={handleClose}>
  <Modal.Header closeButton></Modal.Header>
  <Modal.Body>
    <div className="fromss_boxx tr_histry">
      <div className="cntr_box_all">
        <div className="form_arar singoutss">
            <div class="sus_lists">
                <h2>Are you sure you want to Logout ?</h2>
                <p className="link_chooss">
                  <Link to="">Yes</Link>
                  <Link to="" className="act">No</Link>
                </p>
            </div>
        </div>
    </div>
   </div>
  </Modal.Body>
</Modal>

	<div className="all_suport_bg">
	<section className="ser_v_araeea evnetsts profiles">
		<div className="container">
		<div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Referral</span></div>
		<div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/referral" className="act"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><Button variant="primary" onClick={videoshow}><img src="/images/side_m_ic9.png" alt="" /> Logout</Button></Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>
                      </div>
                    </div>
					
					<div className="col-lg-9">
						<div className="entnts_tabss profff_eventss profilss">
							<h4 className="br_space">Referral</h4>
							<form action="">
							<div className="al_pg_suportss">
							 <div className="top_sectss">	
                              <p>Refer a friend and every time they spend , you get rewarded too !</p>
                              <ul>
								<li><img src="/images/circle-cliks.png" alt="" /> Invite your friends</li>
								<li><img src="/images/circle-cliks.png" alt="" /> They create profile</li>
								<li><img src="/images/circle-cliks.png" alt="" /> You earn money !</li>
							  </ul>
							 </div>

							 <div className="link_supports">
								<div className="ad_list">
									<input type="text" value="https://pearsystem.in/worldtrust/login-landing?referral=MID689119ress" placeholder="" className="ad_likns" />
									<button type="submit" value="Copy Link" className="btn_supot">Copy Link</button>
								</div>
								<p>More ways to invite your friends</p>
								<ul>
								  <li><Link to="" className="lnk_brdss"><img src='/images/ic_facebc.png' alt='' /></Link></li>
								  <li><Link to="" className="lnk_brdss"><img src='/images/ic_links.png' alt='' /></Link></li>
								  <li><Link to="" className="lnk_brdss"><img src='/images/ic_whats.png' alt='' /></Link></li>
								  <li><Link to="" className="lnk_brdss"><img src='/images/ic_twitter.png' alt='' /></Link></li>
								</ul>
							 </div>
							</div>


							<div className="al_pg_suportss">
							 <div className="top_sectss mb-3">	
                              <p>Referred By</p>
							 </div>

							 <div className="link_supports">
								<div className="ad_list singalss">
									<input type="text" value="IJGUIERJGOEJG945HI4H" placeholder="" className="ad_likns" />
									<Link to="/referral-list"><button type="" value="Your Total Referral (02)" className="btn_supot">Your Total Referral (02)</button></Link>
								</div>
							 </div>
							</div>
							</form>
						</div>
					</div>

		  </div>

		
		</div>
	</section>
</div>


	</>
    )
};

export default Login;