import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from 'react-image-upload'

const Careers = () => {
    const [startDate, setStartDate] = useState(new Date());

    function getImageFileObject(imageFile) {
        console.log({ imageFile })
      }
    
      function runAfterImageDelete(file) {
        console.log({ file })
      }

    return (
        <>
<div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
            <div className="container">
                <div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Profile</span></div>
                <div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information" className="act"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/referral"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><img src="/images/side_m_ic9.png" alt="" /> Logout</Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-9">
                    <div className="form_boxx profilss">
                  
                  <div className="form_arar mt-0"> 
                  <h4>Tax Information</h4> 
                  <div className="mang_und_listst mt-0" id="two_arts_prt">  
                    <form action="">
                       <div className="row">                           
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Monthly Income</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter your monthly income" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Annual Income</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter your annual income" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>Income Source</label>
                                    <input type="text" class="form-control" name="" placeholder="Enter your income source" />
                                </div>
                            </div>
                       </div>

                        <div className="row mt-4 justy_centr">
                          <div className="col-lg-4 col-6">
                            <div className="mgs_titales">
                             <h6 className="titaless">NID Image</h6>
                             <div id="imgss_upload">
                             <ImageUploader
                                    onFileAdded={(img) => getImageFileObject(img)}
                                    onFileRemoved={(img) => runAfterImageDelete(img)}
                                    />
                             </div>
                             <p className="t_sizes">Recommended image size 150x150</p>
                             </div>
                          </div>

                          <div className="col-lg-4 col-6">
                          <div className="mgs_titales">
                          <h6 className="titaless">Driving License Image</h6>
                             <div id="imgss_upload">
                             <ImageUploader
                                    onFileAdded={(img) => getImageFileObject(img)}
                                    onFileRemoved={(img) => runAfterImageDelete(img)}
                                    />
                             </div>
                             <p className="t_sizes">Recommended image size 150x150</p>
                             </div>
                          </div>


                          <div className="col-lg-4 col-6">
                          <div className="mgs_titales">
                          <h6 className="titaless">Passport Image</h6>
                             <div id="imgss_upload">
                             <ImageUploader
                                    onFileAdded={(img) => getImageFileObject(img)}
                                    onFileRemoved={(img) => runAfterImageDelete(img)}
                                    />
                             </div>
                             <p className="t_sizes">Recommended image size 150x150</p>
                             </div>
                          </div>

                          <div className="col-lg-12 mt-5">
                              <div class="form-group">
                                <button type="submit" value="Update Profile" class="sub_mitess">Update Tax Information</button>
                              </div>
                            </div>
                        </div>

                        
                    </form>
                  </div> 
                  </div> 
                </div>
                    </div>
                </div>
                
            </div>
        </section>
        </div>
    </>
    )
};

export default Careers;