import { Link } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  homeslider,
  options,
  CharityCampaign,
  EventsMobiles,
  ParentMobiles,
  NewsFeaturedStories,
} from "../Utils/SLiderOption";
import { Modal, Button } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import "animate.css";
import BannerPage from "../Components/Home/BannerPage";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const Home = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [aboutData, setAboutData] = useState({});
  const [faqList, setFaqList] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [highCampaignData, setHighCampaignData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [chooseUsData, setChooseUsData] = useState([]);
  const [happyCustomersData, setHappyCustomersData] = useState([]);
  const [newsData, setNewsData] = useState([]);

  const getAllData = async () => {
    try {
      setLoading(true)
      // Banner
      const banerData = await axiosClient.get("/web/getAllBanners?language=en");
      if (!banerData.data.error) {
        setBanner(banerData.data.data);
      }

      // About
      const getAboutData = await axiosClient.get("/web/getAboutUs?language=en");
      if (!getAboutData.data.error) {
        setAboutData(getAboutData.data.data);
      }

      // Campaign
      const getCampaignData = await axiosClient.get(
        "/web/getTopCampaigns?language=en"
      );
      if (!getCampaignData.data.error) {
        setCampaignData(getCampaignData.data.data);
      }

      // High Campaign
      const getHighCampaignData = await axiosClient.get(
        "/web/getHightDonateEvent?language=en"
      );
      if (!getHighCampaignData.data.error) {
        setHighCampaignData(getCampaignData.data.data);
      }

      // Events
      const getEventsData = await axiosClient.get(
        "/web/getTopEvents?language=en"
      );
      if (!getEventsData.data.error) {
        setEventsData(getEventsData.data.data);
      }

      // Partner
      const getPartnerData = await axiosClient.get(
        "/web/getHomePartner?language=en"
      );
      if (!getPartnerData.data.error) {
        setPartnerData(getPartnerData.data.data);
      }

      // use us
      const getChooseUsData = await axiosClient.get(
        "/web/getWhyUseWorldTrust?language=en"
      );
      if (!getChooseUsData.data.error) {
        setChooseUsData(getChooseUsData.data.data);
      }

      // happy customers
      const getHappyCustomersData = await axiosClient.get(
        "/web/getWebTestimonials?language=en"
      );
      if (!getHappyCustomersData.data.error) {
        setHappyCustomersData(getHappyCustomersData.data.data);
      }

      // news
      const getNewsData = await axiosClient.get(
        "/web/getTopNewsList?language=en"
      );
      if (!getNewsData.data.error) {
        setNewsData(getNewsData.data.data);
      }

      // Faq
      const getFaqListData = await axiosClient.get(
        "/web/getFaqList?language=en"
      );
      if (!getFaqListData.data.error) {
        setFaqList(getFaqListData.data.data);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };


  useEffect(() => {
    getAllData();
  }, []);

  
  // if (loading) {
  //   return (
  //     <div className="loader">
  //       <BeatLoader color="#097C81" />
  //     </div>
  //   );
  // }

  return (
    <>
      <Modal
        className="all_supportss"
        id="video_pop"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/O0_46dWQsts?si=1ousg9fC6gATpnz6"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Modal.Body>
      </Modal>
      {/* ------ Banner -----*/}
      <BannerPage banner={banner} setBanner={setBanner} />
      {/* ------ End Banner -----*/}
      {/* ------ About Worldtrust -----*/}
      {aboutData.id !== undefined && (
        <section className="ser_v_araeea about_us" id="AboutUs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="middlss mb_lssn mb_view_show">
                  <h4>{aboutData.name}</h4>
                  <h6>{aboutData.sub_title}</h6>
                </div>
                <div className="img_bx_ar_ard">
                  <img src={aboutData.image} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="rdy_cont_area">
                  <div className="middlss">
                    <h4 className="mb_view_hide">{aboutData.name}</h4>
                    <h6 className="mb_view_hide">{aboutData.sub_title}</h6>
                    <p>{aboutData.description}</p>

                    <Link to="/about-us " className="vw_details">
                      Know More <img src="/images/rd_tk_r_arrow.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* ------ End About Worldtrust -----*/}
      {/* ------ Count Down -----*/}
      <section
        className="ser_v_araeea pt-4 animate_animated animate_moveUp"
        id="contters"
      >
        <div className="container">
          <div className="brd_top_btm">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="counts_list">
                  <div className="contter">
                    <h3 className="counter">
                      <CountUp end={12000} />
                    </h3>
                    <span className="cnt_k">k+</span>
                  </div>
                  <p>Total Donations Raised</p>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="counts_list">
                  <div className="contter">
                    <h3 className="counter">
                      <CountUp end={9000} />
                    </h3>
                    <span className="cnt_k">k+</span>
                  </div>
                  <p>Total Beneficiaries help</p>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="counts_list">
                  <div className="contter">
                    <h3 className="counter">
                      <CountUp end={2000} />
                    </h3>
                    <span className="cnt_k">k+</span>
                  </div>
                  <p>Total hours of volunteers clocked</p>
                </div>
              </div>

              <div className="col-lg-3 col-6">
                <div className="counts_list">
                  <div className="contter">
                    <h3 className="counter">
                      <CountUp end={1000} />
                    </h3>
                    <span className="cnt_k">k+</span>
                  </div>
                  <p>Total amount of merits points given out</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------ End Count Down -----*/}
      {/* ------ Achievers -----*/}
      <section className="ser_v_araeea pt-0" id="Achievers">
        <div className="container">
          <div className="al_sec_ctxt text-center">
            <div className="marg_cnteers">
              <div className="cntstts">
                <span className="awrd">
                  <img src="/images/gold_aword.png" alt="" />
                </span>
                <h3>Achievers</h3>
                <span className="awrd">
                  <img src="/images/gold_aword.png" alt="" />
                </span>
              </div>
            </div>
          </div>

          <div className="tablt_dat_listst">
            <div className="al_data_lists">
              <div className="tabs_menuss wrapper">
                <div className="ctx_contnt">Leaderboard</div>
                <Tabs>
                  <div className="ctx_tabsss">
                    <div className="tab-wrapper">
                      <div className="tabs_list">
                        <TabList>
                          <Tab>Donor</Tab>
                          <Tab>Volunteer</Tab>
                          <Tab>Company</Tab>
                        </TabList>
                        <span className="share_links">
                          <img src="/images/share-android.png" alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="content-wrapper">
                    <TabPanel>
                      <div className="table-responsive">
                        <table className="table table-striped borddr_none">
                          <tbody>
                            <tr>
                              <td>Rank</td>
                              <td>Volunteer</td>
                              <td>Month</td>
                              <td>Amount</td>
                              <td>Merits</td>
                            </tr>

                            <tr>
                              <td>
                                <img src="/images/Medals.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>
                                <img src="/images/Silver.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>
                                <img src="/images/Silver.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="table-responsive">
                        <table className="table table-striped borddr_none">
                          <tbody>
                            <tr>
                              <td>Rank</td>
                              <td>Volunteer</td>
                              <td>Month</td>
                              <td>Amount</td>
                              <td>Merits</td>
                            </tr>

                            <tr>
                              <td>
                                <img src="/images/Medals.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>
                                <img src="/images/Silver.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>
                                <img src="/images/Silver.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="table-responsive">
                        <table className="table table-striped borddr_none">
                          <tbody>
                            <tr>
                              <td>Rank</td>
                              <td>Volunteer</td>
                              <td>Month</td>
                              <td>Amount</td>
                              <td>Merits</td>
                            </tr>

                            <tr>
                              <td>
                                <img src="/images/Medals.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>
                                <img src="/images/Silver.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>
                                <img src="/images/Silver.png" alt="" />
                              </td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy1.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>
                                <div className="usr_namess">
                                  <img src="/images/user_dummy2.png" alt="" />
                                </div>
                                <span className="namess">Messiah Watkins</span>
                              </td>
                              <td>August</td>
                              <td>200.00 USD</td>
                              <td>50</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
            <img src="/images/tabless.png" className="mb_view_hide" alt="" />
          </div>
        </div>
      </section>
      {/* ------ End Achievers -----*/}
      {/* ------ Charity Campaign -----*/}
      <section className="ser_v_araeea pt-0" id="charity_campaign">
        <div className="container">
          <div className="al_sec_ctxt">
            <h2>Charity Campaign</h2>
          </div>
          {campaignData.length !== 0 && (
            <>
              <div className="row">
                {campaignData.map((item, index) => {
                  const globalAmount = parseFloat(item.global_amount);
                  const tillDonationAmount = parseFloat(
                    item.till_donation_amount
                  );

                  // Calculate the percentage
                  const percentage = (tillDonationAmount / globalAmount) * 100;
                  return (
                    <div className="col-lg-4 mb_view_hide" key={index}>
                      <div className="cm_box_listst">
                        <div className="cm_mg_box">
                          <img src={item.donation_images} alt="" />
                        </div>
                        <div className="cnt_al_boxx">
                          {item.user && (
                            <span className="sm_txt">
                              {item.user.name}{" "}
                              <img src="/images/gr_tik_sm.png" alt="" />
                            </span>
                          )}
                          <h3>{item.donation_title}</h3>
                          <div
                            className="max-h-130 hideScrollbar"
                            dangerouslySetInnerHTML={{
                              __html: item.donation_short_description,
                            }}
                          ></div>
                          <div className="skills">
                            <ProgressBar
                              completed={percentage}
                              bgColor={"#097C81"}
                              height={"12px"}
                              labelSize={"12px"}
                              transitionDuration={"0.3s"}
                            />
                          </div>
                          <h6>
                            Raised {tillDonationAmount} USD{" "}
                            <span className="sm_t">of {globalAmount} USD</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* ---------- Mobile View ---------- */}
                <div className="col-lg-12 CharityCampaign mb_view_show">
                  <OwlCarousel
                    className="slider-items owl-carousel dots_allss"
                    id="three_points"
                    {...CharityCampaign}
                  >
                    {campaignData.map((item, index) => {
                      const globalAmount = parseFloat(item.global_amount);
                      const tillDonationAmount = parseFloat(
                        item.till_donation_amount
                      );

                      // Calculate the percentage
                      const percentage =
                        (tillDonationAmount / globalAmount) * 100;
                      return (
                        <div className="item" key={index}>
                          <div className="cm_box_listst">
                            <div className="cm_mg_box">
                              <img src={item.donation_images} alt="" />
                            </div>
                            <div className="cnt_al_boxx">
                              {item.user && (
                                <span className="sm_txt">
                                  {item.user.name}{" "}
                                  <img src="/images/gr_tik_sm.png" alt="" />
                                </span>
                              )}
                              <h3>{item.donation_title}</h3>
                              <p>{item.donation_short_description}</p>
                              <div className="skills">
                                <ProgressBar
                                  completed={percentage}
                                  bgColor={"#097C81"}
                                  height={"12px"}
                                  labelSize={"12px"}
                                  transitionDuration={"0.3s"}
                                />
                              </div>
                              <h6>
                                Raised {tillDonationAmount} USD{" "}
                                <span className="sm_t">
                                  of {globalAmount} USD
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
                {/* ---------- End Mobile View ---------- */}
                <div className="col-lg-12 mt-5">
                  <div className="vei_als">
                    <Link to="/donate">
                      <span className="managess_bnt">
                        All Campaigns{" "}
                        <img src="/images/chevron-right.png" alt="" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      {/* ------ End Charity Campaign -----*/}
      {/* ------ Events -----*/}
      {eventsData.length !== 0 && (
        <section className="ser_v_araeea pt-0" id="events_hm">
          <div className="container">
            <div className="al_sec_ctxt">
              <h2>Events</h2>
            </div>

            <div className="row">
              <div className="col-lg-8">
                <div className="evt_bgr">
                  <div className="dats_time">{eventsData[0].event_date}</div>
                  <img src={eventsData[0].image} alt="" />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="evntts_cnt">
                  <h3>{eventsData[0].title}</h3>
                  <span>{eventsData[0].event_time}</span>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: eventsData[0].description,
                      }}
                    ></div>
                  </p>

                  <Link className="btn_read">
                    Register Now <img src="/images/ar_right_icon.png" alt="" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              {eventsData.slice(1).map((res, i) => (
                <div key={i} className="col-lg-3 mb_view_hide">
                  <Link to="/events">
                    <div className="evt_bgr_sm">
                      <div className="mg_bxxs">
                        <img src={res.image} alt="" />
                      </div>
                      <span>{res.event_date}</span>
                      <p>{res.short_title}</p>
                    </div>
                  </Link>
                </div>
              ))}

              {/* ---------- Mobile View ---------- */}
              <div className="col-lg-12 CharityCampaign mb_view_show">
                <OwlCarousel
                  className="slider-items owl-carousel dots_allss"
                  id="three_points"
                  {...EventsMobiles}
                >
                  {eventsData.map((res) => (
                    <div className="item">
                      <Link to="/events">
                        <div className="evt_bgr_sm">
                          <div className="mg_bxxs">
                            <img src={res.image} alt="" />
                          </div>
                          <span>{res.event_date}</span>
                          <p>{res.short_title}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
              {/* ---------- End Mobile View ---------- */}

              <div className="col-lg-12 mt-5">
                <div className="vei_als">
                  <Link to="/events">
                    <span className="managess_bnt">
                      Browse All Events{" "}
                      <img src="/images/chevron-right.png" alt="" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* ------ End Events -----*/}
      {/* ------ Partner -----*/}
      {partnerData.length !== 0 && (
        <section className="ser_v_araeea pt-0" id="part_hm">
          <div className="container">
            <div className="al_sec_ctxt text-center">
              <h2>Partner</h2>
            </div>
            <div className="row mb_view_hide">
              {partnerData.map((item, index) => (
                <div className="col-lg-2" key={index}>
                  <div className="part_boxxx">
                    <img src="/images/part_lgo1.png" alt="" />
                  </div>
                </div>
              ))}
            </div>

            <div className="row mb_view_show">
              {/* ---------- Mobile View ---------- */}
              <div className="col-lg-12 CharityCampaign mb_view_show">
                <OwlCarousel
                  className="slider-items owl-carousel dots_allss"
                  id="three_points"
                  {...ParentMobiles}
                >
                  {partnerData.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="part_boxxx">
                        <img src={item.partner_logo} alt="" />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
              {/* ---------- End Mobile View ---------- */}
            </div>
          </div>
        </section>
      )}
      {/* ------ End Partner -----*/}
      {/* ------ When people help people change happens -----*/}
      <section className="ser_v_araeea pt-4" id="when_people">
        <div className="container">
          {highCampaignData && highCampaignData.length !== 0 && (
            <div className="row">
              <div className="col-lg-7 mb_view_hide">
                <div className="when_pep_hlp">
                  <h3>{highCampaignData[0].donation_title}</h3>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: highCampaignData[0].donation_short_description,
                    }}
                  ></div>
                  {/* <p>
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    aut natus minima eum obcaecati voluptas est aliquam soluta.
                    Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                    aut natus minima eum obcaecati voluptas est aliquam soluta.
                  </p> */}
                  <Link to="" className="btn_read">
                    Donate Now <img src="/images/ar_right_icon.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="when_mg_bx">
                  <img
                    src={highCampaignData[0].donation_images}
                    className="h-100"
                    alt=""
                  />
                  <div className="retings_boxx">
                    <h3>
                      210 Campaign <span>Running Listed </span>
                    </h3>

                    <div className="skills">
                      <ProgressBar
                        completed={(
                          (highCampaignData[0].till_donation_amount /
                            highCampaignData[0].global_amount) *
                          100
                        ).toFixed(2)}
                        bgColor={"#D05959"}
                        height={"12px"}
                        labelSize={"12px"}
                        transitionDuration={"0.3s"}
                      />
                    </div>

                    <span className="cam_runnings">195 campaigns running</span>
                    <p>
                      {highCampaignData[0].donation_count} Donations{" "}
                      <Link to="">
                        <img src="/images/arrow_surcals.png" alt="" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              {/* ------ Mobile View -----*/}
              <div className="col-lg-7 mb_view_show">
                <div className="when_pep_hlp">
                  <h3>{highCampaignData[0].donation_title}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: highCampaignData[0].donation_short_description,
                    }}
                  ></div>
                  <Link to="" className="btn_read">
                    Donate Now <img src="/images/ar_right_icon.png" alt="" />
                  </Link>
                </div>
              </div>
              {/* ------ End Mobile View -----*/}
            </div>
          )}
        </div>
      </section>
      {/* ------ End When people help people change happens -----*/}F
      {chooseUsData.id !== undefined && (
        <section className="ser_v_araeea pt-4" id="why_choose_hm">
          <div className="container">
            <div className="al_sec_ctxt">
              <h2>{chooseUsData.name}</h2>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <div className="why_boxxx">
                  <img src={chooseUsData.image} alt="image" />
                </div>
              </div>
              <div className="col-lg-5">
                <div
                  dangerouslySetInnerHTML={{ __html: chooseUsData.description }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* ------ End Why choose worldtrust -----*/}
      {/* ------ Worldtrust makes happy customers -----*/}
      {happyCustomersData.length !== 0 && (
        <section className="ser_v_araeea" id="Worldtrust_hm">
          <div className="container">
            <div className="worlds_box">
              <div className="al_sec_ctxt">
                <h2>Worldtrust makes happy customers</h2>
              </div>

              <div>
                <OwlCarousel
                  className="slider-items owl-carousel"
                  id="three_points"
                  {...options}
                >
                  {happyCustomersData.map((res, i) => (
                    <div key={i}>
                      <div className="item">
                        <div className="hd_ara_cntxt">
                          <span className="mg_aryya">{res.designation}</span>
                          <div className="contents_arar">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: res.description,
                              }}
                            ></div>
                            <h3>{res.user_name}</h3>
                          </div>
                        </div>
                      </div>

                      {/* <div className="item">
                    <div className="hd_ara_cntxt imgss">
                      <span className="mg_aryya">Customer Stories</span>
                      <div className="usrre_mgss">
                        <img
                          src="/images/user_sld.png"
                          className="user_mgss"
                          alt=""
                        />
                        <div className="play_tikss">
                          <Button variant="primary" onClick={videoshow}>
                            <img
                              src="/images/play.png"
                              alt=""
                              className="vdo_ply video-btn"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* ------ End Worldtrust makes happy customers -----*/}
      {/* ------ News & Featured stories -----*/}
      <section className="ser_v_araeea pt-4" id="news_featured_hm">
        <div className="container">
          <div className="al_sec_ctxt">
            <h2>News & Featured stories</h2>
          </div>

          <div className="row">
            {newsData.slice(0, 2).map((res, i) => {
              return (
                <div className="col-lg-4 mb_view_hide" key={i}>
                  <div className="news_box_arara">
                    <div className="nes_mg_arae">
                      <img src={res.image} alt="" />
                    </div>
                    <span className="daytss">
                      08-09-2023
                      {res.blog_category && (
                        <span className="ctgrees">
                          {res.blog_category.name}
                        </span>
                      )}
                    </span>
                    <h3>{res.name}</h3>
                    <div
                      className="max-h-185"
                      dangerouslySetInnerHTML={{
                        __html: res.description,
                      }}
                    ></div>

                    {/* <div> 
                      <HtmlWithLimit
                        htmlString={res.description}
                        limit={50}
                      />
                    </div> */}
                  </div>
                </div>
              );
            })}

            <div className="col-lg-4 mb_view_hide">
              <div className="news_lists_parts">
                {newsData.slice(2).map((res, i) => (
                  <div className="un_lissts" key={i}>
                    <div className="mg_arara">
                      <img src="/images/news_sm_mg1.png" alt="" />
                    </div>
                    <div className="contenner">
                      <span>
                        08-09-2023 <span className="ctgrees">Category</span>
                      </span>
                      <p>
                        Lorem ipsum dolor sit amet. Sed consetur necessitatibus
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* ---------- Mobile View ---------- */}
            <div className="col-lg-12 CharityCampaign mb_view_show">
              <OwlCarousel
                className="slider-items owl-carousel dots_allss"
                id="three_points"
                {...NewsFeaturedStories}
              >
                {newsData.map((res, i) => (
                  <div className="item" key={i}>
                    <div className="news_box_arara">
                      <div className="nes_mg_arae">
                        <img src={res.image} alt="" />
                      </div>
                      <span className="daytss">
                        08-09-2023
                        {res.blog_category && (
                          <span className="ctgrees">
                            {res.blog_category.name}
                          </span>
                        )}
                      </span>
                      <h3>{res.name}</h3>
                      <p>{res.description}</p>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
            {/* ---------- End Mobile View ---------- */}

            <div className="col-lg-12 mt-5">
              <div className="vei_als">
                <Link to="/blogs">
                  <span className="managess_bnt">
                    View More <img src="/images/chevron-right.png" alt="" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------ End News & Featured stories -----*/}
      {/* ------ Frequently Asked Questions -----*/}
      <section className="ser_v_araeea pt-4" id="news_featured_hm">
        <div className="container">
          <div className="al_sec_ctxt">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="accordion_container">
            <Accordion>
              {faqList.length !== 0 &&
                faqList.map((res, i) => (
                  <AccordionItem header={res.question} key={i}>
                    <div dangerouslySetInnerHTML={{ __html: res.answer }} />
                  </AccordionItem>
                ))}
            </Accordion>
          </div>

          <div className="mt-5">
            <div className="vei_als">
              <Link to="">
                <span className="managess_bnt">
                  All FAQs <img src="/images/chevron-right.png" alt="" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* ------ End Frequently Asked Questions -----*/}
    </>
  );
};

export default Home;
