import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const blogsslider = {
    margin:0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};

const NewsFeaturedStories = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};

const RecentEvents = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};

const Blogs = () => {
    return (
        <>
<div className="all_suport_bg">
    <section className="ser_v_araeea evnt_slisder allss" id="blogss">
        <div className="container">
            <OwlCarousel className="slider-items owl-carousel" id="home_sliserss" {...blogsslider}>
                <div className="item">
                    <div className="sld_partsss">
                        <img src="/images/blog_sld.png" alt="" />
                        <div className="over_cntsst">
                            <h2>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h2>
                            <p><img src="/images/ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</p>
                        </div>
                    </div>
                </div>

                <div className="item">
                    <div className="sld_partsss">
                        <img src="/images/blog_sld.png" alt="" />
                        <div className="over_cntsst">
                            <h2>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h2>
                            <p><img src="/images/ic_sharp-location-on.png" alt="" /> Phnom Penh , Combodia</p>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </div>
    </section>

    <section className="ser_v_araeea" id="news_featured_hm">
    <div className="container">
        <div class="al_sec_ctxt"><h2>News & Featured stories</h2></div>
        <OwlCarousel className="slider-items owl-carousel" id="three_points" {...NewsFeaturedStories}>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/news_bg_sm.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/news_bg_sm1.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/news_bg_sm2.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/news_bg_sm1.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
        </OwlCarousel>
    </div>
   </section>



   <section className="ser_v_araeea pt-2" id="news_featured_hm">
    <div className="container">
        <div class="al_sec_ctxt"><h2>Recent Events</h2></div>
        <OwlCarousel className="slider-items owl-carousel" id="three_points" {...RecentEvents}>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/RecentEvents_sm1.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/RecentEvents_sm2.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/RecentEvents_sm3.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
            <div className="item">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/RecentEvents_sm1.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut 
                    cfbg ...... <Link to="" className="pg_links">know more</Link></p>
                </div>
            </div>
        </OwlCarousel>
    </div>
   </section>


   <section className="ser_v_araeea pt-2" id="news_featured_hm">
    <div className="container">
        <div class="al_sec_ctxt mb-0"><h2>Updates</h2></div>

        <div className="row">
            <div className="col-lg-4 mt-5">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/ev_update1.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut cfbg ...... <Link to="" className="pg_links"> know more</Link></p>
                </div>
            </div>


            <div className="col-lg-4  mt-5">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/ev_update2.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut cfbg ...... <Link to="" className="pg_links"> know more</Link></p>
                </div>
            </div>

            <div className="col-lg-4 mt-5">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/ev_update3.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut cfbg ...... <Link to="" className="pg_links"> know more</Link></p>
                </div>
            </div>

            <div className="col-lg-4 mt-5">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/ev_update4.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut cfbg ...... <Link to="" className="pg_links"> know more</Link></p>
                </div>
            </div>

            <div className="col-lg-4 mt-5">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/ev_update5.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut cfbg ...... <Link to="" className="pg_links"> know more</Link></p>
                </div>
            </div>

            <div className="col-lg-4 mt-5">
                <div className="news_box_arara">
                    <div className="nes_mg_arae">
                        <img src="/images/ev_update6.png" alt="" />
                    </div>
                    <span className="daytss">08-09-2023 <span className="ctgrees">Category</span></span>
                    <h3>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus</h3>
                    <p>Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut natus minima eum Lorem ipsum dolor sit amet. Sed consequatur necessitatibus aut cfbg ...... <Link to="" className="pg_links"> know more</Link></p>
                </div>
            </div>


            <div className="col-lg-12 mt-5">
                <div className="vei_als">
                    <a href="javascript:void(0);">
                        <span className="managess_bnt">See More <img src="/images/chevron-right.png" alt="" /></span>
                    </a>
                </div>
            </div>

        </div>
    </div>
</section>
   

</div>

	</>
    )
};

export default Blogs;