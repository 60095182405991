import { Link } from "react-router-dom";
import { React, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const evntssslider = {
  margin: 0,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["<", ">"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};

const Login = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);

  const [bannerData, setBannerData] = useState([]);
  const [bannerImages, setBannerImages] = useState([]);
  const [tabInd, setTabInd] = useState(1);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllData = async () => {
    try {
      setLoading(true);
      const getBannerContent = await axiosClient.get(
        `/events/getBannerEvents?language=en`
      );
      if (!getBannerContent.data.error) {
        setBannerData(getBannerContent.data.BannerContent);
        setBannerImages(getBannerContent.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getUpcomingData = async () => {
    try {
      const upcomingData = await axiosClient.get(
        `/events/getUpComingEvents?language=en`
      );
      if (!upcomingData.data.error) {
        setEvents(upcomingData.data.data.rows);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.log(error);
      setEvents([]);
    }
  };

  const getPastEvents = async () => {
    try {
      const pastData = await axiosClient.get(
        `/events/getPastEvents?language=en`
      );
      if (!pastData.data.error) {
        setEvents(pastData.data.data.rows);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.log(error);
      setEvents([]);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (tabInd === 1) {
      getUpcomingData();
    } else {
      getPastEvents();
    }
  }, [tabInd]);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }

  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="registeress"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx">
            <div className="titalss">
              <h3>Register Now</h3>
            </div>
            <form action="">
              <div className="unfildss" id="rediiobnt">
                <p>Register now for this event as :</p>
                <div className="rdo_boxx">
                  <p>
                    <input type="radio" id="test1" name="radio-group" checked />
                    <label for="test1">Participant</label>
                  </p>
                  <p>
                    <input type="radio" id="test2" name="radio-group" />
                    <label for="test2">Volunteer</label>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Name<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Enter Name"
                />
              </div>

              <div class="form-group">
                <label>
                  Email ID<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Enter Email ID"
                />
              </div>

              <div class="form-group">
                <label>
                  Phone Number<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Enter Phone Number"
                />
              </div>

              <div class="form-group check_txt" id="checkox">
                <input id="check1" name="" type="checkbox" value="yes" />
                <label for="check1">I confirm my phone number</label>
              </div>

              <div class="form-group">
                <div className="ck_titalss">
                  <h3>Choose your best way for communication</h3>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-4">
                    <div className="check_txtx" id="checkox">
                      <input id="check2" name="" type="checkbox" value="yes" />
                      <label for="check2">Whatsapp</label>
                    </div>
                  </div>

                  <div className="col-lg-4 col-4">
                    <div className="check_txtx" id="checkox">
                      <input id="check3" name="" type="checkbox" value="yes" />
                      <label for="check3">Phone Call</label>
                    </div>
                  </div>

                  <div className="col-lg-4 col-4">
                    <div className="check_txtx" id="checkox">
                      <input id="check4" name="" type="checkbox" value="yes" />
                      <label for="check4">Email</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mt-4">
                <button type="submit" value="Register" class="sub_mitess">
                  Register
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <section className="ser_v_araeea green_bg eventss_pgs" id="about_pgs">
        <img src="/images/flo_partss.png" className="flo_partss" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="al_sprts_pages">
                {bannerData.name && <h3>{bannerData.name}</h3>}
              </div>
            </div>
            <div className="col-lg-6">
              {bannerData.sub_title && (
                <div className="cnt_datas mb_view_hide">
                  {bannerData.sub_title}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="ser_v_araeea abt_mgeess_bx evnt_slisder allss">
        <div className="container">
          <OwlCarousel
            className="slider-items owl-carousel"
            id="home_sliserss"
            {...evntssslider}
          >
            {bannerImages.map((res, i) => (
              <div className="item" key={i}>
                <div className="sld_partsss">
                  <div className="upcm_bxx">Upcoming Event</div>
                  <img src={res.image} className="mg_ngr" alt="" />
                  <div className="over_cntsst">
                    <div className="datss">
                      <img src="/images/uil_calender.png" alt="" />{" "}
                      {res.event_date}
                    </div>
                    <h2>{res.title}</h2>
                    <p>{res.short_title}</p>
                    <p>
                      <img src="/images/ic_sharp-location-on.png" alt="" />
                      {res.event_location}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>

      <section className="ser_v_araeea pt-0 evnetsts">
        <div className="container">
          <div className="entnts_tabss">
            <Tabs>
              <div className="tabs_list">
                <TabList>
                  <Tab onClick={() => setTabInd(1)}>Upcoming Events</Tab>
                  <Tab onClick={() => setTabInd(2)}>Past Events</Tab>
                </TabList>
                <div className="fillerss">
                  <img src="/images/mage_filter.png" alt="" />
                </div>
              </div>

              <div className="content-wrapper">
                <TabPanel>
                  <div className="main_tabs_data">
                    {events.map((res) => (
                      <div className="un_data_list">
                        <Link to={`/events-details/${res.id}`}>
                          <div className="usr_mgss">
                            <img src="/images/events_sm.png" alt="" />
                          </div>
                        </Link>
                        <div className="cntent_tx">
                          <div className="dat_time">
                            <span className="datess">
                              <img src="/images/g_uil_calender.png" alt="" /> 20
                              May 2024
                            </span>
                            <span className="spacess">&nbsp;</span>
                            <span className="datess">
                              <img src="/images/tabler_clock.png" alt="" /> 4:00
                              - 6:00 pm
                            </span>
                          </div>
                          <Link to="/events-details">
                            <h2>{res.title}</h2>
                          </Link>
                          <p>{res.short_title}</p>
                          <div className="adress">
                            <div className="adrss">
                              <img
                                src="/images/g_ic_sharp-location-on.png"
                                alt=""
                              />{" "}
                              {res.event_location}
                            </div>
                            <div className="red_mr">
                              <Button variant="primary" onClick={videoshow}>
                                <Link to="" className="red_mr">
                                  Register Now{" "}
                                  <img src="/images/ar_right_icon.png" alt="" />
                                </Link>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* <div className="paginationss">
                      <ul>
                        <li>
                          <img src="/images/pagi_left.png" alt="" />
                        </li>
                        <li>
                          <Link to="" className="act">
                            1
                          </Link>
                        </li>
                        <li>
                          <Link to="">2</Link>
                        </li>
                        <li>
                          <Link to="">3</Link>
                        </li>
                        <li>
                          <Link to="">4</Link>
                        </li>
                        <li>
                          <Link to="">5</Link>
                        </li>
                        <li>
                          <img src="/images/pagi_right.png" alt="" />
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="main_tabs_data">
                    {events.map((res) => (
                      <div className="un_data_list">
                        <Link to="/events-details">
                          <div className="usr_mgss">
                            <img src={res.image} alt="" />
                          </div>
                        </Link>
                        <div className="cntent_tx">
                          <div className="dat_time">
                            <span className="datess">
                              <img src="/images/g_uil_calender.png" alt="" />{" "}
                              {res.event_date}
                            </span>
                            <span className="spacess">&nbsp;</span>
                            <span className="datess">
                              <img src="/images/tabler_clock.png" alt="" />{" "}
                              {res.event_time}- {res.event_time_end}
                            </span>
                          </div>
                          <Link to="/events-details">
                            <h2>{res.title}</h2>
                          </Link>
                          <p>{res.short_title}</p>
                          <div className="adress">
                            <div className="adrss">
                              <img
                                src="/images/g_ic_sharp-location-on.png"
                                alt=""
                              />{" "}
                              {res.event_location}
                            </div>
                            <div className="red_mr">
                              <Button variant="primary" onClick={videoshow}>
                                <Link to="" className="red_mr">
                                  Register Now{" "}
                                  <img src="/images/ar_right_icon.png" alt="" />
                                </Link>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* <div className="paginationss">
                      <ul>
                        <li>
                          <img src="/images/pagi_left.png" alt="" />
                        </li>
                        <li>
                          <Link to="" className="act">
                            1
                          </Link>
                        </li>
                        <li>
                          <Link to="">2</Link>
                        </li>
                        <li>
                          <Link to="">3</Link>
                        </li>
                        <li>
                          <Link to="">4</Link>
                        </li>
                        <li>
                          <Link to="">5</Link>
                        </li>
                        <li>
                          <img src="/images/pagi_right.png" alt="" />
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
