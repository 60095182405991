import { Link } from "react-router-dom";
import {React, useState} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Donate = () => {
    return (
        <>

        <div className="all_suport_bg">
            <section className="ser_v_araeea" id="pay_alss">
                <div className="container">
                    <div className="cntr_box_all">
                      <div className="top_user">
                       <div className="usr_pickss"><img src="/images/donate_dt_sm1.png" alt="" /></div>  
                       <div className="cnt_tx_cntss">
                       <span>You’re supporting</span> Every Heartbeat Matters: Saving Aiden's Heart [Heart Menders]  
                       </div>
                      </div> 

                      <div className="form_arar cardssz">
                       <form action="">
                        <div className="price_list">
                          <h6>Enter your Donation</h6>
                         <ul>
                          <li>$50</li>
                          <li>$100</li>
                          <li>$200</li>
                          <li>$300</li>
                          <li>$500</li>
                          <li>$1,000</li>
                         </ul>                          
                        </div>

                        <div class="form-group marg_cds">
                          <span className="setss">.00</span>
                          <input type="text" class="form-control" name="" placeholder="USD" required="" />
                        </div>

                        <div class="form-group manags_cntxt">
                          Donation Amount <span className="pricde">USD 300.00</span>
                        </div>
                       
                        <div className="brd_liness mb-4">&nbsp;</div>

                        <div class="form-group marg_cds">
                          <span className="setss">20.00</span>
                          <input type="text" class="form-control" name="" placeholder="Tip" required="" />
                        </div>

                        
                        <div class="form-group manags_cntxt">
                          Payment
                        </div>

                        
                        <div class="form-group manags_cntxt">
                        Total Donation Amount <span className="pricde">USD 320.00</span>
                        </div>

                        <div className="price_list link_btnss mt-3">
                          <h6>Select Payment Option</h6>
                          <Link to="" className="act">Bank</Link>
                          <Link to="">Card</Link>                        
                        </div>

                        <div className="mang_und_listst">
                          <h6>Card Details</h6>
                          <div class="form-group">
                           <label>Card Type</label>
                           <select className="slt_st fulls_d">
                                 <option value="">Choose card type</option>
                           </select>
                          </div>

                          <div class="form-group">
                           <label>Card No.</label>
                           <input type="text" class="form-control" name="" placeholder="xxxx xxxx xxxx xxxx" required="" />
                          </div> 

                          <div class="form-group">
                            <div className="row">
                              <div className="col-lg-5">                              
                              <label>Expiry Date</label>
                              <div className="bothss_slts">
                                <select className="slt_st">
                                 <option value="">Feb</option>
                                </select>

                                <select className="slt_st">
                                 <option value="">2022</option>
                                </select>
                              </div>
                              </div>

                              <div className="col-lg-3">
                               <label>CVV</label>
                               <input type="text" class="form-control" name="" placeholder="xxxx" required="" />
                              </div>
                            </div>
                           
                          </div>  

                          <div class="form-group mt-4 mb-4">
                            <button type="submit" value="Donate Now" className="sub_mitess">Donate Now</button>
                          </div>

                          <div className="form-group">
                           <p className="by_textxt">By choosing the payment method above, you agree to the Worldtrust <Link to="">Terms of Service</Link> and acknowledge the <Link to="">Privacy Notice.</Link>
                           </p>
                          </div>  

                          <div className="gpr_g_textss">
                           <img src="/images/trapy_gr.png" alt="" />
                           <div className="grp_txtx">
                           <h6>Worldtrust protects your donation</h6>
                           <p>We guarantee you a full refund for up to a year in the rare case that fraud occurs. See our Worldtrust Giving Guarantee.</p>
                           </div>
                          </div>      
                        </div>

                        
                       </form>  
                      </div>                     
                    </div>
                </div>
            </section>
        </div>

        </>
    )
};

export default Donate;