import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from 'react-image-upload'



const Careers = () => {
    const [startDate, setStartDate] = useState(new Date());

    function getImageFileObject(imageFile) {
        console.log({ imageFile })
      }
    
      function runAfterImageDelete(file) {
        console.log({ file })
      }

    return (
        <>
<div className="all_suport_bg">
        <section className="ser_v_araeea profiles">
            <div className="container">
                <div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Gratitude</span></div>
                <div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to="/impact"><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to="/Profile"><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to="/kyc"><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to="/tax-information"><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="/Gratitude" className="act"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to="/my-events"><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to="/transaction-history"><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to="/referral"><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to="/settings"><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><img src="/images/side_m_ic9.png" alt="" /> Logout</Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-9">
                     <div className="form_boxx Impact Gratitude  profilss">
                      {/* ---------- Mobile View ---------- */}
                     <div className="row mb_view_show">
                     <div className="col-lg-3">
                        <div className="badge_mg"><img src="/images/badge_big.png" alt="" /></div>
                      </div>
                     </div>
                     {/* ---------- Mobile View ---------- */}

                     <h4 class="br_space">Badges</h4>
                     <div className="row">
                      <div className="col-lg-9">
                       <div className="badges_arara">
                        <ul>
                         <li><img src="/images/badge_iron.png" alt="" /></li>
                         <li><img src="/images/Badge_bronze.png" alt="" /></li>
                         <li>
                           <div className="data_area"> 
                            <div className="mg_area"><img src="/images/badge_silver.png" alt="" /></div>
                            <p>100 Points to go</p>
                           </div>
                         </li>
                         <li>
                           <div className="data_area">
                           <div className="mg_area"><img src="/images/badge_silver_gold.png" alt="" /></div>
                            <p>300 Points to go</p>
                           </div>
                         </li>

                         <li>
                           <div className="data_area">
                           <div className="mg_area"><img src="/images/badge_silver_diamond.png" alt="" /></div>
                            <p>500 Points to go</p>
                           </div>
                         </li>

                        </ul>
                       </div>
                      </div>
                      <div className="col-lg-3 mb_view_hide">
                        <div className="badge_mg"><img src="/images/badge_big.png" alt="" /></div>
                      </div>
                     </div>
                     

                      <div className="row mt-2">
                      <div className="col-lg-12">
                          <div className="Imepctgrp tabless">
                            <div className="top_cnt_impct two_partss">
                              <div className="titals_im">Gratitude List</div>
                              
                              <div className="don_impacts">
                                <div className="down"><Link to="/gratitude-system">View More</Link></div>
                              </div>
                            </div>

                            <div class="table-responsive mt-2">          
                              <table class="table table-striped al_suport_items mb-0">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Event / Campaign</th>
                                    <th>Date</th>
                                    <th>Amount</th>                                    
                                    <th>Merits</th>
                                    <th>Rating</th>
                                    <th>Status</th>
                                    <th className="last">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td>                                    
                                  </tr>

                                  <tr>
                                    <td>2</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="goldan_tx_w">Inprocess</td>
                                    <td className="green_tx_w"><img src="/images/eye-crossed.png" alt="" /></td>                                    
                                  </tr>


                                  <tr>
                                    <td>3</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="red_tx_w">Pending</td>
                                    <td className="green_tx_w"><img src="/images/eye-crossed.png" alt="" /></td>                                    
                                  </tr>

                                  <tr>
                                    <td>4</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td>                                    
                                  </tr>

                                  <tr>
                                    <td>5</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td>                                    
                                  </tr>

                                  <tr>
                                    <td>6</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td>                                    
                                  </tr>

                                  <tr>
                                    <td>7</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td>                                    
                                  </tr>

                                  <tr>
                                    <td>8</td>
                                    <td>fund collection for flood survivals</td>
                                    <td>Aug, 28 2024 02:28:22 PM </td>
                                    <td>200.00 USD</td>                                    
                                    <td className="green_tx_w">5</td>
                                    <td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>
                                    <td className="green_tx_w">Completed</td>
                                    <td className="green_tx_w"><img src="/images/eye_ico.png" alt="" /></td>                                    
                                  </tr>
                                  
                                </tbody>
                              </table>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                  
                     </div>
                    </div>
                </div>
                
            </div>
        </section>
        </div>
    </>
    )
};

export default Careers;