import React from "react";
import { Outlet, Route } from "react-router-dom";
import Footer from "../pages/common/Footer";
import Header from "../pages/common/Header";

const PrivateRoutes = () => {
  return (
    <>
      <Header />
        <Outlet />
      <Footer />
    </>
  );
};

export default PrivateRoutes;
