import React from "react";
import { Outlet, Route } from "react-router-dom";
import Header from "../pages/common/Header";

const AuthRoutes = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default AuthRoutes;
