import { Link, useParams } from "react-router-dom";
import { React, useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../Services/Axios/axios";

const Donate = () => {
  const { id } = useParams();
  const [eventData, setEventData] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const videoshow = () => setShow(true);

  const getSingleData = async () => {
    try {
      const data = await axiosClient.get(`/events/getEvents/${id}?language=en`);
      if (!data.data.error) {
        setEventData(data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleData();
  }, []);
  return (
    <>
      <Modal
        className="all_supportss sm_sizess"
        id="registeress"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="fromss_boxx">
            <div className="titalss">
              <h3>Register Now</h3>
            </div>
            <form action="">
              <div className="unfildss" id="rediiobnt">
                <p>Register now for this event as :</p>
                <div className="rdo_boxx">
                  <p>
                    <input type="radio" id="test1" name="radio-group" checked />
                    <label for="test1">Participant</label>
                  </p>
                  <p>
                    <input type="radio" id="test2" name="radio-group" />
                    <label for="test2">Volunteer</label>
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label>
                  Name<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Enter Name"
                />
              </div>

              <div class="form-group">
                <label>
                  Email ID<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Enter Email ID"
                />
              </div>

              <div class="form-group">
                <label>
                  Phone Number<span className="red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Enter Phone Number"
                />
              </div>

              <div class="form-group check_txt" id="checkox">
                <input id="check1" name="" type="checkbox" value="yes" />
                <label for="check1">I confirm my phone number</label>
              </div>

              <div class="form-group">
                <div className="ck_titalss">
                  <h3>Choose your best way for communication</h3>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-4">
                    <div className="check_txtx" id="checkox">
                      <input id="check2" name="" type="checkbox" value="yes" />
                      <label for="check2">Whatsapp</label>
                    </div>
                  </div>

                  <div className="col-lg-4 col-4">
                    <div className="check_txtx" id="checkox">
                      <input id="check3" name="" type="checkbox" value="yes" />
                      <label for="check3">Phone Call</label>
                    </div>
                  </div>

                  <div className="col-lg-4 col-4">
                    <div className="check_txtx" id="checkox">
                      <input id="check4" name="" type="checkbox" value="yes" />
                      <label for="check4">Email</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mt-4">
                <button type="submit" value="Register" class="sub_mitess">
                  Register
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {eventData.id && (
        <div className="all_suport_bg">
          <section className="ser_v_araeea" id="do_telss_page">
            <div className="container">
              <div className="al_sec_ctxt do_detailss">
                <h2>
                  Lorem ipsum dolor : sit amet Sed consequatur{" "}
                  <span className="blocks">necessitatibus</span>
                </h2>
              </div>

              <div className="row">
                <div className="col-lg-8">
                  <div className="us_bg_imgas">
                    <img src="/images/donate_dtls.png" alt="" />
                  </div>

                  <div className="tm_context">
                    <div className="set_tm_dat">
                      <div className="st_adrss">
                        <img
                          src="/images/g_ic_sharp-location-on.png"
                          className="map_ic"
                          alt=""
                        />{" "}
                        Phnom Penh , Combodia
                      </div>
                      <div className="st_adrss">
                        <div className="part_right">
                          <span className="datess">
                            <img
                              src="/images/g_uil_calender.png"
                              className="datss"
                              alt=""
                            />{" "}
                            20 May 2024
                          </span>
                          <span className="datess">
                            <img
                              src="/images/tabler_clock.png"
                              className="timess"
                              alt=""
                            />{" "}
                            4:00 - 6:00 pm
                          </span>
                        </div>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                      aut natus minima eum obcaecati voluptas est aliquam
                      soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed
                      consequatur necessitatibus aut natus minima eum obcaecati
                      voluptas est aliquam soluta. Aut quia minusLorem Lorem
                      ipsum dolor sit amet. Sed consequatur necessitatibus aut
                      natus minima eum obcaecati voluptas eLorem ipsum dolor sit
                      amet. Sed consequatur necessitatibus aut natus minima eum
                      obcaecati voluptas est aliquam soluta. Aut quia minus
                      Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                      aut natus minima eum obcaecati voluptas est aliquam
                      soluta. Aut quia minusLorem Lorem ipsum dolor sit amet.
                      Sed consequatur necessitatibus aut natus minima eum
                      obcaecati voluptas e
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet. Sed consequatur necessitatibus
                      aut natus minima eum obcaecati voluptas est aliquam
                      soluta. Aut quia minus Lorem ipsum dolor sit amet. Sed
                      consequatur necessitatibus aut natus minima eum obcaecati
                      voluptas est aliquam soluta. Aut quia minusLorem Lorem
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="rt_donat_lists mb_view_hide">
                    <div id="form_arar_alss">
                      <h4>Lorem ipsum dolor : sit amet Sed</h4>
                      <p>Register now for this event</p>
                      <form action="">
                        <div class="form-group">
                          <label>
                            Name<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name=""
                            placeholder="Enter Name"
                          />
                        </div>

                        <div class="form-group">
                          <label>
                            Email ID<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name=""
                            placeholder="Enter Email ID"
                          />
                        </div>

                        <div class="form-group">
                          <label>
                            Phone Number<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name=""
                            placeholder="Enter Phone Number"
                          />
                        </div>

                        <div class="form-group">
                          <label>
                            Enter No. of Pax<span className="red">*</span>
                          </label>
                          <select class="slt_st fulls_d">
                            <option value="">Choose No. of Pax</option>
                          </select>
                        </div>
                        <div class="form-group mt-4">
                          <button
                            type="submit"
                            value="Donate Now"
                            class="sub_mitess"
                          >
                            Register
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="btn_manages mt-4 mb_view_show">
                    <Link to="" className="nrmllss regitterss text-center">
                      <Button variant="primary" onClick={videoshow}>
                        Register{" "}
                      </Button>
                    </Link>
                  </div>

                  <div className="btn_manages mb_view_hide mt-3">
                    <Link to="" className="nrmllss">
                      Share Event{" "}
                      <img src="/images/w-share-android.png" alt="" />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ser_v_araeea pt-0 ev_tabdss evnetsts">
            <div className="container">
              <div className="entnts_tabss">
                <Tabs>
                  <div className="tabs_list">
                    <TabList>
                      <Tab>Photos</Tab>
                      <Tab>Videos</Tab>
                    </TabList>
                  </div>

                  <div className="content-wrapper">
                    <TabPanel>
                      <div className="main_tabs_data">
                        <div className="row">
                          <div className="col-lg-3 col-6">
                            <div className="ph_gellry">
                              <img src="/images/evn_sm_ph1.png" alt="" />
                            </div>
                          </div>

                          <div className="col-lg-3 col-6">
                            <div className="ph_gellry">
                              <img src="/images/evn_sm_ph2.png" alt="" />
                            </div>
                          </div>

                          <div className="col-lg-3 col-6">
                            <div className="ph_gellry">
                              <img src="/images/evn_sm_ph3.png" alt="" />
                            </div>
                          </div>

                          <div className="col-lg-3 col-6">
                            <div className="ph_gellry">
                              <img src="/images/evn_sm_ph4.png" alt="" />
                            </div>
                          </div>

                          <div className="col-lg-3 col-6">
                            <div className="ph_gellry">
                              <img src="/images/evn_sm_ph5.png" alt="" />
                            </div>
                          </div>

                          <div className="col-lg-3 col-6">
                            <div className="ph_gellry">
                              <img src="/images/evn_sm_ph6.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="main_tabs_data">
                        <div className="row">
                          <div className="col-lg-6 col-6">
                            <div className="vedioo">
                              <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/uyVCneA_e3o?si=irYPRsjkuJHAgbDn"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>

                          <div className="col-lg-6 col-6">
                            <div className="vedioo">
                              <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/uyVCneA_e3o?si=irYPRsjkuJHAgbDn"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </section>

          <section className="ser_v_araeea evnetsts pt-0">
            <div className="container">
              <div className="al_sec_ctxt">
                <h2>Similar Events</h2>
              </div>
              <div className="entnts_tabss">
                <div className="content-wrapper mt-0">
                  <div className="main_tabs_data">
                    <div className="un_data_list">
                      <Link to="/events-details">
                        <div className="usr_mgss">
                          <img src="/images/events_sm.png" alt="" />
                        </div>
                      </Link>
                      <div className="cntent_tx">
                        <div className="dat_time">
                          <span className="datess">
                            <img src="/images/g_uil_calender.png" alt="" /> 20
                            May 2024
                          </span>
                          <span className="spacess">&nbsp;</span>
                          <span className="datess">
                            <img src="/images/tabler_clock.png" alt="" /> 4:00 -
                            6:00 pm
                          </span>
                        </div>
                        <Link to="/events-details">
                          <h2>
                            Lorem ipsum dolor : sit amet Sed consequatur
                            necessitatibus
                          </h2>
                        </Link>
                        <p>
                          Lorem ipsum dolor sit amet. Sed consequatur
                          necessitatibus aut natus minima eum obcaecati voluptas
                          est aliquam soluta. Aut quia minus Lorem ipsum dolor
                          sit amet. Sed consequatur necessitatibus aut natus
                          minima eum obcaecati voluptas est aliquam soluta. Aut
                          quia minusLorem Lorem ipsum dolor sit amet. Sed
                          consequatur necessitatibus aut natus minima eum
                          obcaecati voluptas e
                        </p>
                        <div className="adress">
                          <div className="adrss">
                            <img
                              src="/images/g_ic_sharp-location-on.png"
                              alt=""
                            />{" "}
                            Phnom Penh , Combodia
                          </div>
                          <div className="red_mr">
                            <Link to="" className="red_mr">
                              <Button variant="primary" onClick={videoshow}>
                                Register Now
                                <img src="/images/ar_right_icon.png" alt="" />
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="un_data_list">
                      <Link to="/events-details">
                        <div className="usr_mgss">
                          <img src="/images/events_sm.png" alt="" />
                        </div>
                      </Link>
                      <div className="cntent_tx">
                        <div className="dat_time">
                          <span className="datess">
                            <img src="/images/g_uil_calender.png" alt="" /> 20
                            May 2024
                          </span>
                          <span className="spacess">&nbsp;</span>
                          <span className="datess">
                            <img src="/images/tabler_clock.png" alt="" /> 4:00 -
                            6:00 pm
                          </span>
                        </div>
                        <Link to="/events-details">
                          <h2>
                            Lorem ipsum dolor : sit amet Sed consequatur
                            necessitatibus
                          </h2>
                        </Link>
                        <p>
                          Lorem ipsum dolor sit amet. Sed consequatur
                          necessitatibus aut natus minima eum obcaecati voluptas
                          est aliquam soluta. Aut quia minus Lorem ipsum dolor
                          sit amet. Sed consequatur necessitatibus aut natus
                          minima eum obcaecati voluptas est aliquam soluta. Aut
                          quia minusLorem Lorem ipsum dolor sit amet. Sed
                          consequatur necessitatibus aut natus minima eum
                          obcaecati voluptas e
                        </p>
                        <div className="adress">
                          <div className="adrss">
                            <img
                              src="/images/g_ic_sharp-location-on.png"
                              alt=""
                            />{" "}
                            Phnom Penh , Combodia
                          </div>
                          <div className="red_mr">
                            <Link to="" className="red_mr">
                              <Button variant="primary" onClick={videoshow}>
                                Register Now
                                <img src="/images/ar_right_icon.png" alt="" />
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <div id="Footer_arae">
        <div className="ftr_manages">
          <div className="th_parts_bxx">
            <div className="container">
              <div class="al_sec_ctxt text-center wit_tx">
                <h2>Your reliable, efficient, and impactful way to give</h2>
              </div>
              <div className="row justy_centr">
                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Efficient</h5>
                    <p>
                      Donate swiftly and <span>effortlessly</span>
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Impactful</h5>
                    <p>
                      Direct your support to the{" "}
                      <span>people and causes that matter</span> most to you
                    </p>
                  </div>
                </div>

                <div className="col-lg-4 col-6">
                  <div className="cont_ftr_box">
                    <img src="/images/wallpaper.png" className="wlper" alt="" />
                    <h5>Reliable</h5>
                    <p>
                      Your donation is safeguarded by{" "}
                      <span>the Worldtrust community</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donate;
