import { Link, useLocation, useParams } from "react-router-dom";
import { React, useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const Donate = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [transactionData, setTransactionData] = useState({});
  const [loading, setLoading] = useState(false);
  const getDonationData = async () => {
    try {
      setLoading(true);
      const transactionData = await axiosClient.get(
        `/campaigns/getCampaignTransactionById/${state}?language=en`
      );
      if (!transactionData.data.error) {
        setTransactionData(transactionData.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log(state);

  useEffect(() => {
    getDonationData();
  }, []);
  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <div className="all_suport_bg">
        <section className="ser_v_araeea" id="pay_alss">
          <div className="container">
            <div className="cntr_box_all">
              <div className="form_arar sussess">
                <div class="sus_lists">
                  <img src="/images/suss_tik.png" alt="" />
                  <h2>Donation Successful</h2>
                  <p>
                    Thank you for donating with Worldtrust. Please check your
                    transaction details below
                  </p>
                </div>

                <div className="brd_liness mt-4 mb-4">&nbsp;</div>

                {transactionData.id && (
                  <div className="top_user justify-content-start">
                    <div className="usr_pickss">
                      <img
                        src={transactionData.campaign.donation_images}
                        alt=""
                      />
                    </div>
                    <div className="cnt_tx_cntss">
                      <span>You’re supporting</span>{" "}
                      {transactionData.campaign.donation_title}
                    </div>
                  </div>
                )}

                <div className="brd_liness mt-4 mb-4">&nbsp;</div>
                {transactionData.id && (
                  <>
                    <div class="form-group manags_cntxt black_tx">
                      Transactions Details
                    </div>
                    <div class="form-group manags_cntxt">
                      Transaction ID{" "}
                      <span class="pricde">
                        {transactionData.transactionId}
                      </span>
                    </div>
                    <div class="form-group manags_cntxt">
                      Payment Mode{" "}
                      <span class="pricde">
                        {transactionData.payment_method_type.toUpperCase()}
                      </span>
                    </div>
                    <div class="form-group manags_cntxt">
                      Amount <span class="pricde">{transactionData.final_amount} USD</span>
                    </div>
                  </>
                )}

                {/* <div className="brd_liness mt-2 mb-4">&nbsp;</div>

                <div className="pri_liststs">
                  <div class="form-group manags_cntxt">
                    Card Type <span class="pricde black_tx">Debit Card</span>
                  </div>
                  <div class="form-group manags_cntxt">
                    Card Details{" "}
                    <span class="pricde black_tx">xxxx xxxx xxxx 8432</span>
                  </div>
                </div> */}

                <div className="brd_liness mt-2 mb-4">&nbsp;</div>

                <div className="gpr_g_textss">
                  <img src="/images/g_flor.png" alt="" />
                  <div className="grp_txtx">
                    <h6>
                      <span className="b_text">{transactionData?.merit_points}</span>{" "}
                      <span className="sm_text">Merit Points</span> Credited
                    </h6>
                  </div>
                </div>

                <div className="price_list link_btnss">
                  <Link to="/" className="act">
                    Go to Dashboard
                  </Link>
                </div>

                <div className="mang_und_listst">
                  <div className="form-group">
                    <p className="by_textxt">
                      You will receive a confirmation email once we have
                      verified the donation details. If you have any enquiries
                      please email customer support at
                      <Link to="mailto:support@worldtrust.io">
                        support@worldtrust.io
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Donate;
