import React, { useState, useEffect } from "react";
import LogoIcon from "../../assets/Images/MiniLogo.png";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axiosClient from "../../Services/Axios/axios";

const VerificationPage = () => {
  const navigate = useNavigate();
  const emailId = localStorage.getItem("email");
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const verifyOtp = async () => {
    const otpString = otp.join("");
    try {
      const response = await axiosClient.post("/users/otp-verification", {
        email: emailId,
        otp: otpString,
      });
      if (!response.data.error) {
        Swal.fire({
          icon: "success",
          title: "Verification Successful",
          text: response.data.message,
        }).then(() => {
          navigate("/register");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid OTP",
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      if (error.response && error.response.status === 401) {
        Swal.fire({
          icon: "error",
          title: "Verification Failed",
          text:
            error.response.data.message || "Unauthorized. Please try again.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Verification Failed",
          text: "An error occurred during verification. Please try again later.",
        });
      }
    }
  };

  return (
    <>
      <div className="container-fluid vh-100 d-flex justify-content-center login-container">
        <div className="row w-100 h-100">
          {/* Left side image */}
          <div className="col-md-6 d-none d-md-block">
            {/* <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={LogoIcon} alt="WorldTrust" className="img-fluid" />
            <h5 className="mt-3">Helping with Donations</h5>
          </div> */}
          </div>

          {/* Right side login form */}
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center h-100">
            <div className="card p-4 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex justify-content-center   ">
                <img src={LogoIcon} alt="WorldTrust" />
              </div>
              <h3 className="text-center">Email Verification</h3>
              <p className="text-center fw-bolder w-75 ">
                We have sent an OTP to your registered email , enter and verify
                your email id
              </p>
              <div className="otp-input-container d-flex justify-content-center mb-4">
                {[...Array(4)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="form-control otp-input mx-1" 
                    style={{ width: "40px", textAlign: "center" }}
                    value={otp[index]}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyUp={(e) => {
                      if (e.target.value && index < 3) {
                        const nextInput = e.target.parentElement.children[index + 1];
                        nextInput.focus();
                      }
                      if (e.key === "Backspace" && index > 0) {
                        const prevInput = e.target.parentElement.children[index - 1];
                        prevInput.focus();
                      }
                    }}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-center   ">
                <button className="btn bg-natural" onClick={verifyOtp}>
                  Verify Email Id
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationPage;
