import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { homeslider } from "../../Utils/SLiderOption";
import OwlCarousel from "react-owl-carousel";
import ProgressBar from "@ramonak/react-progress-bar";

const BannerPage = ({ banner, setBanner }) => {
  return (
    <>
      <div className="fulls_wd" id="ovr_cntentss">
        <OwlCarousel
          className="slider-items owl-carousel"
          id="home_sliserss"
          {...homeslider}
        >

          {banner.map((res, i) => {
            const globalAmount = parseFloat(res.campaign.global_amount);
            const tillDonationAmount = parseFloat(
              res.campaign.till_donation_amount
            );

            // Calculate the percentage
            const percentage = (tillDonationAmount / globalAmount) * 100;
            return (
              <div className="item" key={i}>
                <img src={res.image} alt="" className="mb_view_hide h-100" />
                {/* ------ Mobile View MG -----*/}
                <img
                  src={res.image}
                  alt=""
                  id="mb_view_show"
                  className="h-100"
                />
                {/* ------ End Mobile View MG -----*/}
                <div className="container">
                  <div className="hdr_cnt_aarea">
                    <div className="bg_contentss">
                      <h2>{res.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{ __html: res.description }}
                      />
                      {res.redirect_url && (
                        <Link to={res.redirect_url} className="al_bnt_supt">
                          Start Now
                        </Link>
                      )}
                    </div>
                    {res.campaign && (
                      <div className="pustents_conts h-100 ">
                        <div className="ur_tx_area mt-50">
                          <p>
                            <span>Urgent Cause</span>
                          </p>
                          <h5 className="line-clamp-1">{res.campaign.donation_title}</h5>

                          <div className="skills">
                            <ProgressBar
                              completed={percentage}
                              bgColor={"#097C81"}
                              height={"12px"}
                              labelSize={"12px"}
                              transitionDuration={"0.3s"}
                            />
                          </div>

                          <div className="pricess">
                            ₹{res.campaign.global_amount} Raised
                          </div>
                          <h6>
                            {res.campaign.donation_count} Donations{" "}
                            <Link to="/">
                              <img src="images/arrow_surcals.png" alt="" />
                            </Link>
                          </h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    </>
  );
};

export default BannerPage;
