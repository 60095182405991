import { useEffect, useState } from "react";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const TermsAndConditions = () => {
  const [termsCondition, setTermsCondition] = useState();
  const [loading, setLoading] = useState(false);
  const getTermsAndConditionData = async () => {
    try {
      setLoading(true);
      const data = await axiosClient.get(`web/getTermAndCondition?language=en`);
      if (!data.data.error) {
        setTermsCondition(data.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getTermsAndConditionData();
  }, []);
  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      {termsCondition?.id !== undefined && (
        <section className="ser_v_araeea green_bg manages_heis" id="about_pgs">
          <img src="/images/flo_partss.png" className="flo_partss" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="al_sprts_pages">
                  <h3>{termsCondition.name}</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cnt_datas">{termsCondition.sub_title}</div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="ser_v_araeea pt-0" id="page_contents">
        <div className="container">
          <div className="discriptionss">
            <div className="un_cntxtx">
              <h4>Terms & Conditions</h4>
              {termsCondition?.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: termsCondition.description,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
