import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../Services/Axios/axios";
import { BeatLoader } from "react-spinners";

const Careers = () => {
  const [bannerData, setBannerData] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [jobTypeData, setJobTypeData] = useState([]);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    search: "",
    country: "",
    jobType: "",
  });

  const getAllData = async () => {
    try {
      setLoading(true);
      const banerData = await axiosClient.get(
        "/jobs/getBannerInfo?language=en"
      );
      if (!banerData.data.error) {
        setBannerData(banerData.data.data);
      }
      const country = await axiosClient.get("/country");
      if (!country.data.error) {
        setCountryData(country.data.data);
      }
      const jobType = await axiosClient.get("/jobs/getJobsType?language=en");
      if (!jobType.data.error) {
        setJobTypeData(jobType.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    getListingData();
  };

  const getListingData = async () => {
    try {
      const data = await axiosClient.get(
        `/jobs?language=en&pageSize=10&pageNo=1&searchText=${searchData.search}&country_id=${searchData.country}&job_type_id=${searchData.jobType}`
      );
      if (!data.data.error) {
        setListData(data.data.data.rows);
      } else {
        setListData([]);
      }
    } catch (error) {
      console.log(error);
      setListData([]);
    }
  };
  useEffect(() => {
    getAllData();
    getListingData();
  }, []);

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color="#097C81" />
      </div>
    );
  }
  return (
    <>
      <section className="ser_v_araeea green_bg manages_heis" id="about_pgs">
        <img src="/images/flo_partss.png" className="flo_partss" alt="" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="al_sprts_pages">
                {bannerData.name && <h3>{bannerData.name}</h3>}
              </div>
            </div>
            <div className="col-lg-6 mb_view_hide">
              {bannerData.sub_title && (
                <div className="cnt_datas">{bannerData.sub_title}</div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="ser_v_araeea" id="careers_pgs">
        <div className="container">
          <div className="rech_araea">
            <form onSubmit={handleSubmit}>
              <div className="rch_inputss borddr">
                <img src="/images/search.png" alt="" />
                <input
                  type="text"
                  class="form-control"
                  name=""
                  value={searchData.search}
                  onChange={({ target }) =>
                    setSearchData({ ...searchData, search: target.value })
                  }
                  placeholder="Job Title"
                />
              </div>

              <div className="rch_inputss borddr">
                <img src="/images/location_b_sm.png" alt="" />
                <select
                  className="form-control"
                  value={searchData.country}
                  onChange={({ target }) =>
                    setSearchData({ ...searchData, country: target.value })
                  }
                >
                  <option value="">Location</option>
                  {countryData.map((res, i) => (
                    <option key={i} value={res.id}>
                      {res.name}
                    </option>
                  ))}
                </select>
                <img
                  src="/images/arrow_down_g.png"
                  className="slt_arrow"
                  alt=""
                />
              </div>

              <div className="rch_inputss">
                <img src="/images/suitcase-alt.png" alt="" />
                <select
                  className="form-control"
                  value={searchData.jobType}
                  onChange={({ target }) =>
                    setSearchData({ ...searchData, jobType: target.value })
                  }
                >
                  <option value="">Job Type</option>
                  {jobTypeData.map((res, i) => (
                    <option key={i} value={res.id}>
                      {res.type_name}
                    </option>
                  ))}
                </select>
                <img
                  src="/images/arrow_down_g.png"
                  className="slt_arrow"
                  alt=""
                />
              </div>

              <div className="rch_inputss">
                <button type="submit" value="Search" class="sub_mitess">
                  Search
                </button>
              </div>
            </form>
          </div>

          <div className="flt_lilsts_araea">
            {listData.map((res) => (
              <div className="und_lists">
                <div className="ic_part">
                  <img src="/images/suitcase-alt.png" alt="" />
                </div>
                <div className="contents">
                  <h3>{res.title}</h3>
                  <span>{res.company_address}</span>
                  <p>{res.description}</p>
                </div>
                <div class="red_morss">
                  <Link to={`/careers-message/${res.id}`}>Apply Now</Link>
                </div>
              </div>
            ))}

            {/* <div className="paginationss">
              <ul>
                <li>
                  <img src="/images/pagi_left.png" alt="" />
                </li>
                <li>
                  <Link to="" className="act">
                    1
                  </Link>
                </li>
                <li>
                  <Link to="">2</Link>
                </li>
                <li>
                  <Link to="">3</Link>
                </li>
                <li>
                  <Link to="">4</Link>
                </li>
                <li>
                  <Link to="">5</Link>
                </li>
                <li>
                  <img src="/images/pagi_right.png" alt="" />
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
