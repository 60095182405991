import { Link } from "react-router-dom";
import {React, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import CountUp from 'react-countup';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal, Button } from 'react-bootstrap'
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css';




const Login = () => {
    return (
        <>
	
	<div className="all_suport_bg">
	<section className="ser_v_araeea evnetsts profiles">
		<div className="container">
		<div className="bradcams mb_view_hide"><span className="bothss"><Link to="">My Account</Link>  <img src="/images/brd_rowwo.png" alt="" />  Gratitude</span></div>
		<div className="row">
                    <div className="col-lg-3 mb_view_hide">
                      <div className="my_profiless">
                        <div className="top_pro_ct_mg">
                         <div className="pro_imgs"><img src="/images/my_profl.png" alt="" /></div>
                         <h4>Jackson(MU5785)</h4>
                         <p>jacksoncomp@gmail.com</p>
                        </div>

                        <ul className="profile_menus">
                           <li><Link to=""><img src="/images/side_m_ic1.png" alt="" /> Impact</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic2.png" alt="" /> Profile</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic10.png" alt="" /> KYC</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic11.png" alt="" /> Tax Information</Link></li>
                           <li><Link to="" className="act"><img src="/images/side_m_ic3.png" alt="" /> Gratitude</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic4.png" alt="" /> Events</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic5.png" alt="" /> Campaigns</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic6.png" alt="" /> Transactions</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic7.png" alt="" /> Referral</Link></li>
                           <li><Link to=""><img src="/images/side_m_ic8.png" alt="" /> Settings</Link></li> 
                           <li><Link to=""><img src="/images/side_m_ic9.png" alt="" /> Logout</Link></li>
                        </ul>

                        <div className="merit_point">
                         <div className="pnt_logos"><img src="/images/point_logo.png" alt="" /></div>
                         <div className="cnt_textxt">
                            <h2>400</h2>
                            <p>Merit Points</p>
                         </div>
                        </div>
                      </div>
                    </div>
					
					<div className="col-lg-9">
						<div className="entnts_tabss profff_eventss profilss">

                            {/* ---------- Mobile View ---------- */}
                     <div className="row mb_view_show">
                     <div className="col-lg-3">
                        <div className="badge_mg"><img src="/images/badge_big.png" alt="" /></div>
                      </div>
                     </div>
                     {/* ---------- Mobile View ---------- */}

							<h4 className="br_space">Merit Points</h4>
							<div className="row">
								<div className="col-lg-9">
									<div className="guedd_pointts">
                                     <img src="/images/gued_sysy.png" alt="" />	
									 <div className="merit_pointgud">
									  <div className="pnt_logos"><img src="/images/do_bg_logo.png" alt="" /></div>
									  <div className="cnt_textxt">
										<h2>400</h2>
										<p>Merit Points</p>
									  </div>
									 </div>									
									</div>
								</div>
								<div className="col-lg-3 mb_view_hide">
									<div className="badge_mg"><img src="/images/badge_big.png" alt="" /></div>
								</div>
							</div>

							<h4 className="br_space">Transaction List</h4>
							<Tabs>
								<div className="tabs_list prof_eventss" id="al_tbs_neww">
									<div className="tbdsa">
										<TabList>
											<Tab>All(34)</Tab>
											<Tab>Donated</Tab>
										</TabList>
									</div>
								</div>

								<div className="content-wrapper">
									<TabPanel>
									<div class="table-responsive mt-2">          
										<table class="table table-striped al_suport_items mb-0">
											<thead>
											<tr>
												<th>Sr. No.</th>
												<th>Event / Campaign</th>
												<th>Date</th>
												<th>Merit Points</th>                                    
												<th>Rating</th>
												<th className="last">Status</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>1</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>
											</tr>

											<tr>
												<td>2</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>


											<tr>
												<td>3</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>

											<tr>
												<td>4</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>

											<tr>
												<td>5</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                  
											</tr>

											<tr>
												<td>6</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                    
											</tr>

											<tr>
												<td>7</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                  
											</tr>

											<tr>
												<td>8</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>
											
											</tbody>
										</table>
										</div>	
										<div className="pages_listst">
									    <div className="row mt-4">
											<div className="col-lg-6">
												<div className="list_cnts">Showing 1 to 9 of 5 entries</div>
											</div>
											<div className="col-lg-6">
											<div className="paginationss">
											<ul>
												<li><Link to="">Previous</Link></li>	
												<li><Link to="" className="act">1</Link></li>
												<li><Link to="">2</Link></li>
												<li><Link to="">3</Link></li>	
												<li><Link to="">4</Link></li>
												<li><Link to="">Next </Link></li>	
											</ul>
											</div>			
											</div>
										</div>
									</div>
									</TabPanel>
									<TabPanel>										
										<div class="table-responsive mt-2">          
										<table class="table table-striped al_suport_items mb-0">
											<thead>
											<tr>
												<th>Sr. No.</th>
												<th>Event / Campaign</th>
												<th>Date</th>
												<th>Merit Points</th>                                    
												<th>Rating</th>
												<th className="last">Status</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>1</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>
											</tr>

											<tr>
												<td>2</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>


											<tr>
												<td>3</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>

											<tr>
												<td>4</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>

											<tr>
												<td>5</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                  
											</tr>

											<tr>
												<td>6</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                    
											</tr>

											<tr>
												<td>7</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                  
											</tr>

											<tr>
												<td>8</td>
												<td>fund collection for flood survivals</td>
												<td>Aug, 28 2024 02:28:22 PM </td>
												<td>25</td> 
												<td className="goldan_tx_w flexx">5 <img src="/images/star_gld.png" alt="" /></td>                                   
											    <td className="green_tx_w">Completed</td>                                   
											</tr>
											
											</tbody>
										</table>
										</div>	
										<div className="pages_listst">
									    <div className="row mt-4">
											<div className="col-lg-6">
												<div className="list_cnts">Showing 1 to 9 of 5 entries</div>
											</div>
											<div className="col-lg-6">
											<div className="paginationss">
											<ul>
												<li><Link to="">Previous</Link></li>	
												<li><Link to="" className="act">1</Link></li>
												<li><Link to="">2</Link></li>
												<li><Link to="">3</Link></li>	
												<li><Link to="">4</Link></li>
												<li><Link to="">Next </Link></li>	
											</ul>
											</div>			
											</div>
										</div>
									</div>								
									</TabPanel>
								</div>
							</Tabs>
						</div>
					</div>

		  </div>

		
		</div>
	</section>
</div>


	</>
    )
};

export default Login;